import { ActivityInputField } from "../../../activity-view/activity-creation-form/ActivityCreationForm.styled";

export const UserInputField = ActivityInputField.extend`
  border: ${(props) => props.border};
  font-size: ${(props) => props.fontSize};
  border-radius: ${(props) => props.radius};
  margin-bottom: ${(props) => props.margin};
  padding:1rem 0.5rem;
  height: 3rem;
`;
