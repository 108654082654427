import React, { Component } from "react";
import { ReadingChallengeContainer } from "./ReadingChallengeView.styled";
import ReadingChallengeList from "./reading-challenge-list/ReadingChallengeList";
import { PlusButton, PlusIcon } from "@sc/custom.styled";
import ReadingChallengeRouter from "@r/readingChallengeRouter";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as readingChallenge } from "@st/readingChallenge";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import swal from "sweetalert2";


class ReadingChallengeView extends Component {
  componentWillMount() {
    this.props.readingChallenge.setFetchReadingChallengeList(true);
    this.props.readingChallenge.requestActiveGrade();
  }

  componentWillUnmount() {
    this.props.readingChallenge.setReadingChallengeList([]);
  }

  handleAddClickButton = e => {
    let loc = this.props.location;
    const { permission } = this.props.onboard;
    if (loc.pathname.includes("/reading-challenge/edit")) {
      this.props.history.push("/reading-challenge");
    } else {
      if(Permission.getPermission(permission, "add_reading")){
        this.props.ui.showModal("READINGCHALLENGEFORM");
      }else{
        swal({
          text: "You do not have permission.",
          type: "warning"
        });
      }
      
    }
  };
  render() {
    const { user } = this.props.onboard;

    const isCreatorOpen = this.props.location.pathname.includes(
      "/reading-challenge/edit"
    );
    
    return (
      <ReadingChallengeContainer primary>
        <ReadingChallengeRouter />
        <ReadingChallengeList />
          <PlusButton
            hide={this.props.ui.show_modal}
            onClick={this.handleAddClickButton}
          >
            <PlusIcon
              primary
              alt="Plus Icon"
              cross={isCreatorOpen}
              src={require("static/img/plusicon.png")}
            />
          </PlusButton>
      </ReadingChallengeContainer>
    );
  }
}
export default connectStore({
  ui,
  activity,
  readingChallenge,
  onboard
})(ReadingChallengeView)