import styled from "styled-components";

export const LabelItem = styled.div`
  padding: 12px 5px 12px 8px;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  transition: all 0.3s;
  background: ${props => props.backgroundColor};
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: center;
  &:hover {
    text-decoration: none;
    background: linear-gradient(
      270deg,
      rgba(10, 202, 222, 0),
      rgba(10, 202, 222, 0.3)
    );
    opacity: 1;
  }
  img {
    height: 14px;
  }
`;

export const LabelTitle = styled.div`
  font-size: 13px;
  text-decoration: none;
  margin-left: 10px;
  text-align: left;
  color: ${props => (props.primary ? "#ff8a23" : "white")};
`;

export const LogoImage = styled.img`
  width: 45px;
  height: 45px;
  margin: 40px 30px 5px 40px;
  max-width: 100%;
  text-align: center;
  background: no-repeat;
  animation-name: fadeInLeftBig;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  @keyframes fadeInLeftBig {
    0% {
      opacity: 0;
      transform: translate3d(-500px, 500px, 2000px);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
`;

export const VersionContainer = styled.span`
  color: #f2f2f2;
  font-size: 12px;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-fill-mode: both;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ScrollBarWrapper = styled.div`

::-webkit-scrollbar {
   width: 0px;
}
`;

export const SideMenuMainContainer = styled.div`
  height: 100%;
  width: 140px;
  position: fixed;
  text-align: center;
  //  overflow: hidden;
  overflow: auto;
    z-index: 1;
  top: 0;
  left: 0;
  // background-color: #432666;
  overflow-x: hidden;
  ul {
    margin-top: 20px;
    padding: 0px;
  }
  a {
    text-decoration: none;
    color: white;
    &:hover {
      text-decoration: none;
      background: linear-gradient(
        270deg,
        rgba(10, 202, 222, 0),
        rgba(10, 202, 222, 0.3)
      );

      opacity: 1;
    }
    &:visited {
      color: #ff8a23;
      border-color: #0acade;
    }
  }
`;
