import React, { Component } from "react";
import {
  AlignContentHorizontal,
  EmptyContainer,
  AutoContainer,
  LoadingContainer,
  InfiniteScrollParentContainer,
  InfiniteScrollLoader,
} from "static/styled/custom.styled";
import {
  NavigationHeaderContainer,
  NavigationContainer,
} from "static/styled/NavigationBar.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import { Link } from "react-router-dom";
import SchoolGuidedPathCard from "./school-guided-path-card/SchoolGuidedPathCard";
import InfiniteScroll from "react-infinite-scroller";
import { module as onboard } from "@st/onboard";
import { module as user } from "@st/user";
import {
  SectionDropDown,
  SelectTag,
  OptionTag,
} from "./SchoolGuidedPathList.styled";

class SchoolGuidedPathList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classList: [],
      searchBarEnable: false,
      isComponentRendered: false,
      activeGrade: null,
      units: [],
      sectionList: [],
      selectedSection: null,
      refreshComponent: false,
      grades: [],
      gradeChanged: false,
    };
  }
  componentDidMount() {
    this.setState({ isComponentRendered: true });
    const { user } = this.props.onboard;
    // this.fetchSections("1bf9526b-6c32-4198-9174-e5f55edec9b5",user.school_id);
    // this.fetchUnits();
    // console.log("componentDidMount");
    this.setState({});
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    let grade_id = urlParams.get("grade_id");

    if (grade_id) {
      // get the span tag by using id
      let spanTag = document.getElementById(grade_id);
      if (spanTag) {
        // get the parent a tag
        let parentElement = spanTag.parentElement;
        // add active class
        parentElement.classList.add("active");
      }
    }
    this.fetchProduct(id);
    // let school_id = this.state.sections[0]
    // this.fetchSections("1bf9526b-6c32-4198-9174-e5f55edec9b5",user.school_id);
  }

  async fetchProduct(id) {
    this.setState({});
    // call the API:/admin-guided-product with id as parameter. API is GET
    try {
      this.props.schoolGuidedPath.getGpProduct(id);
    } catch (error) {
      console.error("Failed to fetch product:", error);
    }
  }

  getActiveGradeID = () => {
    const activeAnchor = document.querySelector("a.active");
    // console.log("activeAnchor:", activeAnchor);
    let spanTagInside = "";
    if (activeAnchor) {
      spanTagInside = activeAnchor.querySelector("span");
    }

    // console.log(spanTagInside);
    let spanId = "";
    if (spanTagInside) {
      spanId = spanTagInside.getAttribute("id");
    }
    if (spanId) {
      return spanId;
    }

    // get all the spans with classname = selected and find the id of the span
    const selectedSpans = document.getElementsByClassName("selected");
    // console.log(selectedSpans);
    if (selectedSpans.length > 0) {
      const spanIdFinal = selectedSpans[0].getAttribute("id");
      if (spanIdFinal != undefined) {
        return spanIdFinal;
      }
    }

    // find the grade id from this.state.sectionList[0].grade

    // from the SectionDropdown dropdown , get the selected item and find the  id of that selected option
    const dropdown = document.querySelector(".SectionDropdown");

    if (dropdown != null) {
      // get the first option in dropdown

      const firstOption = dropdown.querySelector(`option`);
      // console.log("firstOption:", firstOption);
      // get the id of firstOption
      if (firstOption) {
        const firstOptionId = firstOption.id;
        if (firstOptionId) {
          return firstOptionId;
        }
      }
    }
    const selectedOptionElement = dropdown.querySelector(`option:checked`);

    // Get the id of the selected option element.
    if (selectedOptionElement) {
      const selectedOptionId = selectedOptionElement.id;
      if (selectedOptionId) {
        return selectedOptionId;
      }
      // Return the id of the selected option element.
    }

    let urlParams = new URLSearchParams(window.location.search);
    let grade_id = urlParams.get("grade_id");

    if (grade_id) {
      // Return the grade ID
      return grade_id;
    }

    // return "1bf9526b-6c32-4198-9174-e5f55edec9b5";
  };

  handleGradeChange = (grade) => {
    // get all a tags with class active
    let activeElements = document.getElementsByClassName("active");
    if (activeElements) {
      // remove active class from all a tags
      for (let i = 0; i < activeElements.length; i++) {
        activeElements[i].classList.remove("active");
      }
    }
    // this.setState({units:[]});
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    this.setState({});
    this.fetchProduct(id);

    this.props.schoolGuidedPath.requestByGradeId(grade.id);
  };

  // handleReadingSearch = (evt) => {
  //   let { schoolGuidedPath } = this.props;
  //   schoolGuidedPath.setSearch(evt.target.value);
  //   setTimeout(() => {
  //     this.props.schoolGuidedPath.requestSchoolGuidedPath();
  //   }, 2000);
  // };

  // handleClearSearch = () => {
  //   let { schoolGuidedPath } = this.props;
  //   schoolGuidedPath.setSearch("");
  //   schoolGuidedPath.requestSchoolGuidedPath();
  // };

  // enableSearchBar = (actionType) => {
  //   if (actionType === "focus" && this.state.searchBarEnable === false)
  //     this.setState({
  //       searchBarEnable: true,
  //     });
  //   else if (actionType === "blur" && this.state.searchBarEnable === true)
  //     this.setState({
  //       searchBarEnable: false,
  //     });
  // };

  fetchAllUnits = () => {
    // console.log("fetchAllUnits");
    // console.log(this.state);
    let updatedUnits = [];
    this.state.sectionList &&
      this.state.sectionList.forEach((item) => {
        // console.log("item:", item);
        if (item.id === "all") {
          // console.log("all");
          updatedUnits = [...updatedUnits, ...item.units];
        }
      });
    // console.log(this.state.sectionList);
    // console.log("updatedUnits:", updatedUnits);
    this.setState({
      units: updatedUnits,
    });
  };

  handleSections = (event) => {
    const dropdown = document.querySelector(".SectionDropdown");
    const selectedValue = dropdown.value;
    // console.log(selectedValue);

    // console.log(this.state.sectionList);
    let units = this.state.sectionList[selectedValue];
    this.state.sectionList.forEach((item) => {
      if (item.id === selectedValue) {
        // console.log("iii:",item.units);
        this.setState({ units: item.units });
      }
    });
  };
  activateGrade = (grade) => {
    let gradeElement = document.getElementById(grade);
    let activeElements = document.getElementsByClassName("active");
    if (activeElements.length > 0) {
      for (let i = 0; i < activeElements.length; i++) {
        activeElements[i].classList.remove("active");
      }
    }

    // get parent a tag
    if (gradeElement) {
      let parentElement = gradeElement.parentElement;
      // add active class
      parentElement.classList.add("active");
    }
    return;
  };
  componentDidUpdate(prevProps, prevState) {
    // console.log("component update:", this.state);
    // console.log("prev props:", prevProps);

    const { active_grade } = this.props.schoolGuidedPath;
    if (active_grade !== prevProps.schoolGuidedPath.active_grade) {
      this.setState({ activeGrade: active_grade });
    }

    if (
      prevProps.schoolGuidedPath.gpProduct !==
      this.props.schoolGuidedPath.gpProduct
    ) {
      // console.log("inside gp product setup update");
      this.setState({ grades: this.props.schoolGuidedPath.gpProduct });
      // console.log(this.state.grades, "isnide update");
    }

    if (prevState.grades !== this.state.grades) {
      if (this.state.grades) {
        let activeGradID = this.getActiveGradeID();
        // console.log("activeGradID:", activeGradID);
        if (activeGradID != null) {
          this.activateGrade(activeGradID);
        } else {
          this.setState({ gradeChanged: true });
        }

        this.setState({ activeGrade: activeGradID });
        // console.log("ag:", this.state.grades[activeGradID]);
        this.setState({
          sectionList:
            activeGradID &&
            this.state.grades[activeGradID] &&
            this.state.grades[activeGradID].sections,
          activeGrade: activeGradID,
        });
        // console.log("state:", this.state);
      }
    }

    if (prevState.sectionList !== this.state.sectionList) {
      this.fetchAllUnits();
    }

    if (
      active_grade !== prevProps.schoolGuidedPath.active_grade &&
      active_grade === undefined
    ) {

      let urlParams = new URLSearchParams(window.location.search);
      // get the url without query params
      let url = window.location.href.split("?")[0];

      let id = urlParams.get("id");
      this.fetchProduct(id);
      // get the url
      // let url = window.location.href;
      let grade_id = this.getActiveGradeID();
      // console.log("grade_id:", grade_id);
      // add grade_id as a query param to the url from getting the active grade id
      let newUrl = url + `?id=${id}&grade_id=${grade_id}`;

      // hard refresh the page by changing the newurl
      window.location.href = newUrl;

      window.history.pushState({}, null, newUrl);
    }
  }

  render() {
    const { location } = this.props;
    const { grades } = this.state;
    let classList =
      this.props.activity.grades &&
      this.props.activity.grades.map((grade, i) => (
        <a
          key={i}
          className={
            this.props.schoolGuidedPath.active_grade &&
            grade.id === this.props.schoolGuidedPath.active_grade
              ? "active"
              : ""
          }
          onClick={() => this.handleGradeChange(grade)}
        >
          <span id={grade.id}> {grade.name}</span>
        </a>
      ));

    let editRoute = location.pathname.includes("/school-guided-path")
      ? "/school-guided-path/edit/"
      : "/school-default-guided-path/edit/";

    let readingChallengeList = (
      <AlignContentHorizontal marginBottom="70px">
        {this.state.units && this.state.units.length > 0 ? (
          this.state.units.map((schoolGuidedPathInfo, index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                <Link to={editRoute + schoolGuidedPathInfo.id}>
                  <SchoolGuidedPathCard
                    schoolGuidedPathInfo={schoolGuidedPathInfo}
                  />
                </Link>
              </div>
            );
          })
        ) : (
          <EmptyContainer>
            <h4>NO UNIT FOR THIS SECTION</h4>
          </EmptyContainer>
        )}
      </AlignContentHorizontal>
    );
    return (
      <AutoContainer>
        <NavigationHeaderContainer shadow>
          <NavigationContainer className="grades_list">
            {classList}
          </NavigationContainer>

          <SectionDropDown>
            {/* <div>Select Dropdown</div>
              <div>
                  <SelectTag
                    className="SectionDropdown"
                    onChange={this.handleSections}
                  >
                    <OptionTag value="" disabled selected>Select Section</OptionTag>
                    {Object.keys(this.state.sectionList).map((key) => (
                        <OptionTag
                          key={key}
                          value={key}
                          >
                          {this.state.sectionList[key]}
                        </OptionTag>
                      ))}
                  </SelectTag>
              </div> */}
            <div
              className="input-group mx-2 my-1"
              style={{ width: "fit-content", border: "1px solid black" }}
            >
              <div className="input-group-prepend">
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect01"
                >
                  Section
                </label>
              </div>
              <SelectTag
                className="SectionDropdown"
                onChange={this.handleSections}
              >
                {this.state.sectionList &&
                  Object.entries(this.state.sectionList).map(([key, value]) => (
                    <OptionTag key={value.id} id={value.grade} value={value.id}>
                      {value.name}
                    </OptionTag>
                  ))}
              </SelectTag>
            </div>
          </SectionDropDown>

          {/* <SearchBarContainer
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            <input
              type="text"
              onChange={this.handleReadingSearch}
              placeholder="Search"
              value={this.props.schoolGuidedPath.search_reading}
              onFocus={() => this.enableSearchBar("focus")}
              onBlur={() => this.enableSearchBar("blur")}
            />
            {this.props.schoolGuidedPath.search_reading.length > 0 && (
              <i
                className="fa fa-times cross"
                onClick={() => this.handleClearSearch()}
              />
            )}
          </SearchBarContainer> */}
        </NavigationHeaderContainer>
        <div className="units">
          <button id="hiddenButton" hidden></button>
          <InfiniteScrollParentContainer noTop>
            <InfiniteScroll
              style={{ position: "relative" }}
              loader={
                <InfiniteScrollLoader className="loader" key={0}>
                  <img
                    alt="loader"
                    width="50"
                    height="50"
                    src={require("static/img/Theme-loader.gif")}
                  />
                </InfiniteScrollLoader>
              }
              useWindow={false}
            >
              {readingChallengeList}{" "}
            </InfiniteScroll>
          </InfiniteScrollParentContainer>
        </div>
      </AutoContainer>
    );
  }
}
export default connectStore({
  activity,
  schoolGuidedPath,
  onboard,
  user,
})(SchoolGuidedPathList);
