import { createSagas } from "redux-box";
import GradeApi from "@s/api/grade";
import SchoolGuidedPathApi from "@s/api/schoolGuidedPath";
import { call, put } from "redux-saga/effects";
//import { push } from "react-router-redux";
import swal from "sweetalert2";

// import store from 'store'


const errorHandler = (err) => {
  // console.log("schoolGuidedPath store err", err);
  swal("Oops!", err.error_message, "error");
};

export default createSagas({
  REQUEST_ACTIVE_GRADE: function*() {
    try {
      const res = yield call(GradeApi.getAllGrades);

      yield put({
        type: "SET_ACTIVE_GRADE",
        grade: res[0].id,
      });

      yield put({
        type: "REQUEST_BY_GRADE_ID",
        gradeId: res[0].id,
      });
    } catch (err) {}
  },

  REQUEST_BY_GRADE_ID: function*({ gradeId }) {
    try {
      yield put({
        type: "SET_FETCH_SCHOOL_GUIDED_PATH_LIST",
        value: true,
      });
      yield put({
        type: "SET_ACTIVE_GRADE",
        grade: gradeId,
      });
      const gState = require("store").default.getState();
      const search = gState.schoolGuidedPath.search_reading;
      const res = yield call(SchoolGuidedPathApi.getUnit, gradeId, 0, search);
      yield put({
        type: "SET_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_SCHOOL_GUIDED_PATH_LIST",
          List: res.result,
        });
      yield put({
        type: "SET_FETCH_SCHOOL_GUIDED_PATH_LIST",
        value: false,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_CONCAT: function*({ offset }) {
    try {
      const gState = require("store").default.getState();
      const gradeId = gState.schoolGuidedPath.active_grade;
      const search = gState.schoolGuidedPath.search_reading;
      const res = yield call(
        SchoolGuidedPathApi.getUnit,
        gradeId,
        offset,
        search
      );

      yield put({
        type: "SET_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_CONCAT_LIST",
          List: res.result,
        });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_SCHOOL_GUIDED_PATH: function*() {
    try {
      yield put({
        type: "SET_FETCH_SCHOOL_GUIDED_PATH_LIST",
        value: true,
      });

      const gState = require("store").default.getState();
      const gradeId = gState.schoolGuidedPath.active_freadom_future_grade;
      const search = gState.schoolGuidedPath.search_reading;
      const res = yield call(SchoolGuidedPathApi.getUnit, gradeId, 0, search);
      yield put({
        type: "SET_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_SCHOOL_GUIDED_PATH_LIST",
          List: res.result,
        });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_SCHOOL_GUIDED_PATH_LIST",
        value: false,
      });
    }
  },

  CREATE_UNIT: function*({ unitData }) {
    let {product_id,...data} = unitData
    try {
      let res = yield call(SchoolGuidedPathApi.createUnit, data);
      if (res.id) {
        yield put({
          type: "GET_GP_PRODUCT",
          // gradeId: res.grade,
          id:product_id
        });
        swal(
          "Created",
          `${res.name.toUpperCase()} created successfully`,
          "success"
        );
      } else {
        swal("Oops!", res.message, "error");
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  UPDATE_UNIT: function*({ unitUpdateData }) {

    let { product_id, ...data } = unitUpdateData;
    // console.log(product_id,unitUpdateData)
    try {
      let res = yield call(SchoolGuidedPathApi.updateUnit, data);
      if (res.id) {
        yield put({
          type: "GET_GP_PRODUCT",
          // gradeId: res.grade,
          id:product_id
        });
        swal("Updated", `${res.name} updated successfully`, "success");
      } else {
        swal("Oops!", res.message, "error");
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  //.......................TASK CRUD ..............................

  REQUEST_TASK_LIST: function*({ unitId }) {
    try {
      const res = yield call(SchoolGuidedPathApi.requestTaskList, unitId);
      yield put({
        type: "SET_TASK_LIST",
        taskList: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  CREATE_TASK: function*({ taskData }) {
    try {
      let res = yield call(SchoolGuidedPathApi.createTask, taskData);
      if (res.id) {
        yield put({
          type: "REQUEST_TASK_LIST",
          unitId: res.unit,
        });
        swal("Created", `${res.name} created successfully`, "success");
      } else {
        swal("Oops!", res.message, "error");
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  UPDATE_TASK: function*({ updateTaskData }) {
    try {
      let res = yield call(SchoolGuidedPathApi.updateTask, updateTaskData);
      if (res.id) {
        yield put({
          type: "REQUEST_TASK_LIST",
          unitId: res.unit,
        });
        swal("Updated", `${res.name} updated successfully`, "success");
      } else {
        swal("Oops!", res.message, "error");
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  DELETE_TASK: function*({ taskId }) {
    try {
      let res = yield call(SchoolGuidedPathApi.deleteTask, taskId);
      if (res.id) {
        yield put({
          type: "REQUEST_TASK_LIST",
          unitId: res.unit,
        });
        swal("Updated", `${res.name} updated successfully`, "success");
      } else {
        swal("Oops!", res.message, "error");
      }
    } catch (err) {
      errorHandler(err);
    }
  },

  GET_GP_PRODUCT: function*({ id }) {
    try {
      let res = yield call(SchoolGuidedPathApi.getGpProduct, id);
      if (res) {
        yield put({
          type: "SET_GP_PRODUCT",
          data: res,
        });
    }
  }
    catch (err) {
      errorHandler(err);
    }
  },
});
