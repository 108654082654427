import React, {Component} from 'react';
import {UploadContainer, SubHeading, TagChipsContainer, TagChips, ThemeButton,FlexBetween} from "static/styled/custom.styled";
import store from 'store'
import action from '@st/activity/actions'
import {connectStore} from "redux-box";
import {module as activity} from "@st/activity";
import {module as readingChallenge} from "@st/readingChallenge";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class TagsSection extends Component {
    constructor(props) {
        super();
    }
    componentWillMount(){
        //this.props.activity.active_activity.tag.map(data => this.props.activity.updateNewTag(data))
    }
    handleTagClick(data){
        let {readingChallenge} = this.props;
        readingChallenge.deleteReadingTag(data);
    }

    render() {
        const {permission} = this.props.onboard;
        const {user} = this.props.onboard

        return (
            <UploadContainer>
                <FlexBetween>
                <SubHeading primary>Related Tags</SubHeading>
                {user.read_only == false && Permission.getPermission(permission, "change_reading")? 
                <ThemeButton onClick={() => store.dispatch(action.setEditorType('tag'))}> + New Tag</ThemeButton>
                :null}
                </FlexBetween>
                {user.read_only == false && this.props.tags && this.props.tags.length > 0 &&  <TagChipsContainer>
                    {this.props.tags.map((data,id) => (
                        <TagChips key={id} onClick={() => this.handleTagClick(data)}>{data}
                            <img className="image-fluid" src={require("static/img/tagCloseIcon.png")} alt="Edit icon"/>
                        </TagChips>
                    ))}
                </TagChipsContainer>}
            </UploadContainer>

        )
    }
}

export default connectStore({
    activity,readingChallenge,onboard
})(TagsSection)
// export  default TagsSection;
