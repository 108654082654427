import Config from "@/services/config/index.js";
import swal from "sweetalert2";
let _headers = { "Content-Type": "application/json" };

const connectionHandler = () => {
  return navigator.onLine
    ? true
    : swal("The Internet?", "That thing is still around?", "question");
};

export const get = (url, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, { headers })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          resolve(res.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export const getList = (url, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, { headers })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          resolve(res.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
export const getProjectsName = (url, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, { headers })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          resolve(res.result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export const getRequest = (url, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, { headers })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export const getCsv = (url, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, { headers })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export const getWOWData = (url, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, { headers })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export const post = (url, data = {}, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res.result);
          }
        })
        .catch((err) => {
          window.err = err;
          reject(err);
        });
    });
  }
};

export const postFormData = (url, data = {}, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers,
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res.result);
          }
        })
        .catch((err) => {
          window.err = err;
          reject(err);
        });
    });
  }
};

export const postRequest = (url, data = {}, headers = _headers) => {
  if (connectionHandler()) {
    let schoolId = data.school;
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      })
        .then((res) => parseFile(res))
        .then((data) =>
          downloadFile(data, `inviteCount - school ( ${schoolId} )`)
        );
    }).catch((err) => {
      window.err = err;
      // reject(err);
    });
  }
};

export const getRquestWithDownload = (url, headers = _headers) => {
         if (connectionHandler()) {
           return new Promise((resolve, reject) => {
             fetch(url, {
               method: "GET",
               headers: {
                 "Content-Type": "text/csv",
                 ...headers
               },
             })
               .then((response) => response.blob())
               .then((blob) => {
                resolve(blob)
               })
               .catch((err) => {
                 window.err = err;
                 reject(err);
               });
           });
         }
       };

export const parseFile = (res) => {
  return new Promise((resolve, reject) => {
    try {
      let _data = [];
      let done = false;
      let reader = res.body.getReader();
      reader.read().then(({ done, value }) => {
        if (done) {
          console.log("done", _data);
          resolve(_data.join(""));
        }
        let text = new TextDecoder("utf-8").decode(value);
        _data.push(text);
        resolve(text);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const downloadFile = (res, file_prefix) => {
  file_prefix = file_prefix + "df";
  file_prefix = file_prefix
    .split(" ")
    .join("_")
    .split("-")
    .join("_")
    .split(".")
    .join("_")
    .toUpperCase();
  let element = document.createElement("a");
  let fileName = file_prefix + ".csv";
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(res)
  );
  element.setAttribute("download", fileName);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const put = (url, data = {}, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "PUT",
        headers,
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res.result);
          }
        })
        .catch((err) => {
          window.err = err;
          reject(err);
        });
    });
  }
};

export const patch = (url, data = {}, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "PATCH",
        headers,
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res.result);
          }
        })
        .catch((err) => {
          window.err = err;
          reject(err);
        });
    });
  }
};

export const deleteCall = (url, headers = _headers) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "DELETE",
        headers,
      })
        .then((res) => (res && res.json()) || {})
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
};

// EXTERNAL SERVER CALL TO INTERNAL DASHBOARD
export const externalGet = (url) => {
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export const getCmfluencyData = (url, data = {}, headers = _headers) => {
  console.log("URL", url, data);
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        headers,
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export const Api = {
         get: (url, headers = _headers) =>
           get(`${Config.api_url}${url}`, headers),
         getList: (url, headers = _headers) =>
           getList(`${Config.wow_url}${url}`, headers),
         postRequest: (url, data, headers = _headers) =>
           postRequest(`${Config.wow_url}${url}`, data, headers),
         postFormData: (url, data, headers = _headers) =>
           postFormData(`${Config.api_url}${url}`, data, headers),
         getCsv: (url, headers = _headers) =>
           getCsv(`${Config.api_url}${url}`, headers),
         getRequest: (url, headers = _headers) =>
           getRequest(`${Config.api_url}${url}`, headers),
         post: (url, data, headers = _headers) =>
           post(`${Config.api_url}${url}`, data, headers),
         put: (url, data, headers = _headers) =>
           put(`${Config.api_url}${url}`, data, headers),
         patch: (url, data, headers = _headers) =>
           patch(`${Config.api_url}${url}`, data, headers),
         deleteCall: (url, headers = _headers) =>
           deleteCall(`${Config.api_url}${url}`, headers),
         externalGet: (url) => externalGet(`${Config.external_api_url}${url}`),
         url: () => Config.api_url,
         getCmfluencyData: (url, data, headers = _headers) =>
           getCmfluencyData(`${Config.cmfluency_url}${url}`, data, headers),
         getRquestWithDownload: (url, headers = _headers) =>
           getRquestWithDownload(`${Config.api_url}${url}`, headers),
       };
