import {createActions,using} from 'redux-box'

export default createActions({
    //....................UNIT CRUD..................
    setFetchSchoolGuidedPathList:using("value"),
    setSchoolGuidedPathList:using("List"),
    requestByGradeId:using("gradeId"),
    requestConcat:using("offset"),
    requestSchoolGuidedPath:using(),

    //CREATE UNIT
    createUnit : using("unitData"),
    updateUnit : using("unitUpdateData"),
    setUnitEditInfo : using("value"),
    setUnitEdit : using("value"),

    // update unit
    unlockUnit : using("unitUpdateData"),

    // //.................Section............................
    requestActiveGrade:using(),

    //................................TASK CRUD............
    requestTaskList : using("unitId"),
    createTask : using("taskData"),
    updateTask : using("updateTaskData"),
    deleteTask : using("taskId"),
    setActiveTask:using("task"),

    // //...............MISC.................
    setSearch:using("search"),
    getGpProduct:using("id")


})