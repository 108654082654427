import React, { Component } from 'react'
import {
    FlexBetween,
    SubHeading,
    UploadContainer,
    ThemeButton
  } from "@sc/custom.styled";
export class NewsFreadLink extends Component {
   copyLink = (input) => {
              setTimeout(() => {
                
                let copyText = document.getElementById(input);
                //console.log('copyText copyText', copyText)
                copyText.focus();
                copyText.select();
                copyText.setSelectionRange(0, 99999); /*For mobile devices*/
                document.execCommand("copy");
                //console.log('copy success', document.execCommand('copy'));
                copyText.blur();
                if (window.getSelection) {window.getSelection().removeAllRanges();}
                else if (document.selection) {document.selection.empty();}
              }, 200)
            } 
        render() {
        return (
            <UploadContainer style={{width:'unset',margin:'14px'}} >
                <FlexBetween>
                <SubHeading primary>Links</SubHeading>
                </FlexBetween>
                <FlexBetween>
                <table style={{width:'100%'}}>
                    <tr>
                        <td style={{width:'15%'}}><p style={{marginBottom:'0.5rem',marginTop:'0.5rem'}}>Web Link</p></td>
                        <td>
                            <input type="text" id="web_link" 
                            style={{width:'100%',opacity:0.7,borderRadius:'5px',marginBottom:'0.5rem',marginTop:'0.5rem'}} readOnly
                            value={"http://cmseducation.getfreadom.com/news_fread/"+this.props.id}/>
                        </td>
                        <td style={{paddingLeft:'1rem',width:'10%'}}>
                            <ThemeButton style={{marginBottom:'0.5rem',marginTop:'0.5rem'}} onClick={()=>this.copyLink('web_link')}>COPY</ThemeButton>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width:'15%'}}><p style={{marginBottom:'0.5rem',marginTop:'0.5rem'}}>App Link</p></td>
                        <td>
                        
                            <input type="text" 
                            style={{width:'100%',opacity:0.7,borderRadius:'5px',marginBottom:'0.5rem',marginTop:'0.5rem'}} readOnly
                            id="app_link"
                            value={this.props.applink} /></td>
                        <td style={{paddingLeft:'1rem',width:'10%'}}>
                            <ThemeButton style={{marginBottom:'0.5rem',marginTop:'0.5rem'}} onClick={()=>this.copyLink('app_link')}>COPY</ThemeButton></td>
                    </tr>
                </table>
                </FlexBetween>
            </UploadContainer>
        )
    }
}

export default NewsFreadLink
