export default {
    fetch_list:false,
    active_grade:{},
    // active_reading_challenge:null,
    // start_date:'',
    // end_date:'',
    // reading_offset:0,

    //..........UNIT STATE................
    unitEditInfo:null,
    unitEdit:false,
    result:null,
    List:[],
    // current_editor_type :'',

    //..........TASK STATE................
    task_list : [],
    active_task: null,

    // //MISC
    search_reading:'',
    gpProduct:null
}
