import styled from "styled-components";
import {
  UserDetailsTableField
} from "static/styled/UserDetailsTableField.styled";
import {
  ThemeButton,
  MainBodyContentContainer,
  FlexCenter
} from "@sc/custom.styled";

export const UserHandleField = styled.div `
  padding: 6px 2px;
  font-size: 12px;
  text-align: center;
  width: 60px;
  margin-left: 8px;
  font-weight: bold;
  color: rgba(108, 48, 135, 1);
  background-color: rgba(108, 48, 135, 0.1);
  word-wrap: break-word;
`;

export const UserDetailsContainerInfo = styled.div `
  overflow-x: auto;
  overflow-y: hidden;
  max-height: calc(100vh - 275px);
  position: relative;
  justify-content: space-between;
`;

export const NavigationHeaderContainer = styled.div `
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  object-fit: contain;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 5px 12px;
`;

export const SearchBarContainer = styled.div `
    // display:inline;
    display:flex;
    width:${props => props.width};
    transition:0.3s all;
    max-height:40px;
    margin:0px 5px;
    border-radius: 20px;
    margin-left:10px;
    position:relative;
    i,.cross {
    font-size: 14px;
    position: absolute;
   display:flex;
    padding:0 10px ;
   top:0;
   left:0;
   height:100%;
   justify-content:center;
    align-items:center;
    }
    .cross{
        right:0;
        left:unset;
        cursor:pointer;
        
        transition:all 0.3s;
        &:hover{
            transform:scale(1.2);
        }
    }
    
    input {
font-size: var(--md-font-size);
    border: 1px  solid rgba(0,0,0,0.15);
    padding:5px 5px 5px 30px;
    border-radius: 20px;
    width: 100%;
    outline: none;
    color:var(--primary-color);
    ::placeholder{
    color: rgba(0,0,0,0.6);}
    `;

export const ToolbarContainer = styled.div `
  padding: 4px 3px;
  img {
    width: 14px;
    height: 14px;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const UserDetailsContainer = MainBodyContentContainer.extend `
  display: flex;
  flex-flow: column nowrap;
  object-fit: contain;
  border-radius: 6px;
  @media (max-width: 1024px) {
    overflow: scroll;
  }
`;

export const UserDetailValueLabels = styled.div `
  display: flex;
  flex-flow: row nowrap;
  font-size: var(--xsm-font-size);
  //z-index:100;
  //position:absolute;
  width: 100%;
  justify-content: space-between;
  padding: 2px 8px;
  margin: 0px;
  border-radius: ${props => (props.primary ? "4px" : "0")};
  background-color: rgba(255, 138, 35, 0.1);
  @media (max-width: 1024px) {
    min-width: fit-content;
  }
`;

export const UserDetailValues = UserDetailValueLabels.extend `
            border-bottom: 1px solid #f3f3f3;
            z-index:90;
            background-color:#fff;
            margin-bottom:5px;
            position:relative;
            transition:all 0.3s;
        &:hover{
            box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.2);
            overflow:visible;
            border-bottom:1px solid transparent;
            border-radius: 4px;
            cursor: pointer;
        `;

export const UserField1 = UserDetailsTableField.extend `
  width: 18%;
  word-wrap: break-word;
`;

export const UserFieldName = UserField1.extend `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 12%;
  @media (max-width: 1024px) {
    min-width: 70px;
    width: 70px;
  }
`;

export const UserFieldEmail = UserField1.extend `
  padding: 0px 10px;
  display: flex;
  justify-content: ${props => (props.align ? "center" : "flex-start")};
  align-items: center;
  word-break: break-word;
  width: ${props => (props.width ? props.width : "18%")};
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    min-width: 100px;
    max-width: ${props => (props.width ? props.width : "140px")};
    word-wrap: break-word;
  }
`;

export const UserField2 = UserDetailsTableField.extend `
  width: 10%;
  justify-content: ${props => (props.align ? "flex-start" : "center")};
  display: flex;
  align-items: center;
  color: ${props =>
    props.color ? props.color : props.primary ? "#ff8f26" : "black"};
  @media (max-width: 1024px) {
    min-width: 100px;
    word-wrap: break-word;
  }
`;
export const UserField3 = UserDetailsTableField.extend `
  width: 4%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  img {
    height: 20px;
  }
  @media (max-width: 1024px) {
    min-width: 20px;
  }
`;

export const NavigationContainer = styled.div `
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    color: black;
    text-align: center;
    text-decoration: none;
    opacity: 0.4;
    font-size: var(--lg-font-size);
    font-weight: 500;
    border-bottom: 2.4px solid #fff;
    padding: 10px;
    margin-left: 10px;
    &:hover {
      border-bottom: 2.4px solid #ff9e31;
      color: #281045;
      transition: 0.3s;
      opacity: 1;
    }
  }

  .active-navigation-field {
    color: #281045;
    border-bottom: 2.4px solid #ff9e31;
    opacity: 1;
  }
`;

export const NavigationToolbar = styled.div `
  display: flex;
  flex-flow: row wrap;
  //width:60%;
  justify-content: flex-end;
  align-items: center;
  //margin-top:4px;
  position: relative;

  @media (max-width: 1150px) {
    .navigation-toolbar {
      //width:70%;
    }
  }
`;

export const ExploreButton = ThemeButton.extend `
  height: 36px;
`;

export const ToggleButton = styled.div `
  width: 45px;
  height: ${props => (props.height ? props.height : "26px")};
  position: relative;
  border-radius: 100px;
  background-color: ${props => (props.color ? props.color : "#ff8f26")};
`;

export const ToggleStatus = styled.div `
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 5px;
  color: #000000;
  cursor: pointer;
  opacity: ${props => (props.primary ? 0.3 : 1)};
`;

export const ToggleHandler = styled.div `
  position: absolute;
  cursor: pointer;
  border-radius: 100%;
  background-color: white;
  left: ${props => (props.left ? props.left : props.primary ? "2px" : "21px")};
  width: ${props => (props.width ? props.width : "22px")};
  transition: 0.3s;
  height: ${props => (props.height ? props.height : "22px")};
  top: 2px;
  padding: 2px;
`;

export const UserDetailsListContainer = styled.div `
  overflow-x: inherit;
  overflow-y: auto;
  max-height: calc(100vh - 220px);
  margin-top: 40px;
`;

export const UserLoadingContainer = styled.div `
  display: flex;
  justify-content: center;
  height: 47px;
  align-items: center;
  align-content: center;
  width: 100%;
`;

export const FileSelectBtn = styled.input `
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  border-radius: 100%;
  top: 0;
  cursor: pointer;
  opacity: 0;
  left: 0;
`;

export const ProfileImageContainer = styled.div `
  border-radius: 100%;
  outline: none;
  position: relative;
  background-color: #e0e0e0;
  width: 44px;
  text-align: center;
  height: 44px;
  margin-left: 8px;
`;

export const ImageContainer = styled.img `
  height: 100%;
  width: 100%;
  max-width: 100%;
  outline: none;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  border-radius: 100%;
`;

export const UserListLoadingContainer = styled.div `
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
  img {
    width: 60px;
    height: 60px;
  }
`;

export const Pagination = FlexCenter.extend `
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 100;
  background: white;
  padding: 5px;
  box-shadow: 0 5px 5px 6px rgba(0, 0, 0, 0.3);
`;

export const PaginationButton = FlexCenter.extend `
  border: 0;
  border-radius: 100px;
  height: 28px;
  width: 28px;
  background: ${props => props.color};
  color: white;
  margin: 0 10px;
  cursor: pointer;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
  }
`;