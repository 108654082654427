export default {
  getPermission(userPermissions, view) {
    console.log("perm:",userPermissions)
    var r = false;
    if (userPermissions && userPermissions.length > 0) {
      if (userPermissions[0].name == "superuser") {
        r = true;
      } else {
        let userPermsn = userPermissions[0].permissions;
        r = userPermsn.some((i) => i.codename.includes(view));
      }
    }
    return r;
  },
  getPermissionMulti(userPermissions, views = []) {
    var r = false;
    if (userPermissions && userPermissions.length > 0) {
      if (userPermissions[0].name == "superuser") {
        r = true;
      } else {
        let userPermsn = userPermissions[0].permissions;
        // userPermsn=[{"codename":"change_activityexperience","content_type":22,"content_type_name":"activity experience"},{"codename":"view_collection","content_type":37,"content_type_name":"collection"},{"codename":"view_grade","content_type":6,"content_type_name":"grade"},{"codename":"view_newsfread","content_type":50,"content_type_name":"news fread"},{"codename":"view_reading","content_type":29,"content_type_name":"reading"},{"codename":"view_story","content_type":43,"content_type_name":"story"}]
        r = userPermsn.some((i) => views.indexOf(i.codename) >= 0);
      }
    }
    return r;
  },
  getPermissionForMenu(userPermissions, views = [], title) {
    var r = false;
    if (userPermissions && userPermissions.length > 0) {
      if (userPermissions[0].name == "superuser" || views.length == 0) {
        r = true;
      } else if (title == "Experiences") {
        let userPermsn = userPermissions[0].permissions;
        // userPermsn=[{"codename":"change_activityexperience","content_type":22,"content_type_name":"activity experience"},{"codename":"view_collection","content_type":37,"content_type_name":"collection"},{"codename":"view_grade","content_type":6,"content_type_name":"grade"},{"codename":"view_newsfread","content_type":50,"content_type_name":"news fread"},{"codename":"view_reading","content_type":29,"content_type_name":"reading"},{"codename":"view_story","content_type":43,"content_type_name":"story"}]
        r = userPermsn.some((i) => views.indexOf(i.content_type_name) >= 0);
      } else {
        let userPermsn = userPermissions[0].permissions;
        for (const it of views) {
          r = userPermsn.some((i) => i.content_type_name.includes(it));
        }
      }
    }
    // console.log("this is value of r", r);
    return r;
  },
  checkRole(userPermissions, role) {
    var r = false;
    if (userPermissions && userPermissions.length > 0) {
      if (userPermissions[0].name == role) {
        r = true;
      }
    }
    return r;
  },
  getFirstPermission(userPermissions = []) {
    var r = "";
    if (userPermissions && userPermissions.length > 0) {
      if (userPermissions[0].name == "superuser") {
        r = "superuser";
      } else {
        r = userPermissions[0].permissions[0].content_type_name;
      }
    }
    return r;
  },
  checkRoutePermission(userPermissions = [], route) {
    var r = "";
    var p = null;
    let res = route.split("/")[1];
    console.log(route, res);

    if (
      route.includes("/flive-code") ||
      route.includes("/leader-board") ||
      route.includes("/welcome") ||
      route.includes("/freadom-future") ||
      route.includes("/school-guided-path") ||
      route.includes("/product") ||
      route.includes("/school-default-guided-path")
      // ||
      // route.includes("/report")
    ) {
      return true;
    }

    switch (res) {
      case "activities":
        p = "activity";
        break;
      case "assessments":
        p = "activity";
        break;
      case "reading-challenge":
        p = "reading";
        break;
      case "news-freads":
        p = "news fread";
        break;
      case "stories":
        p = "story";
        break;
      case "users":
        p = "user";
        break;
      case "tags":
        p = "tag";
        break;
      case "invite-code":
        p = "invite code";
        break;
      case "case-studies":
        p = "testimonial";
        break;
      case "notifications":
        p = "notification";
        break;
      case "payments":
        p = "payment";
        break;
      case "experiences":
        p = "experience";
        break;
      case "school-code":
        p = "school";
        break;
      case "reports":
        p = "report";
        break;
      case "quiz2":
        p = "quiz";
        break;
      default:
        p = null;
    }
    if (userPermissions && userPermissions.length > 0) {
      if (userPermissions[0].name == "superuser") {
        r = true;
      } else {
        let userPermsn = userPermissions[0].permissions;
        r = userPermsn.some((i) => i.content_type_name.indexOf(p) >= 0);
      }
    }
    return r;
  },
};
