import React, { Component } from "react";
import {
  NewsFreedsDescriptionInfoContainer,
  NewsFreedsInfoContainer,
  NewsDescriptionLabel
} from "./NewsFreedsInfo.styled";
import { uploadFile } from "@s/api/file";
import { connectStore } from "redux-box";
import { module as newsFreeds } from "store/newsFreeds";
import { module as level } from "store/level";
import { ActivityUploadImageContainer } from "@c/activity-view/activity-pack-creator/activity-pack-info/ActivityPackInfo.styled";
import {
  ActivityUploadImageButton,
  ChangeImageButton,
  ImageContainer,
  SelectFileIn
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { ImageLoader } from "static/styled/custom.styled";
import moment from "moment";
import {
  StorySectionLabelsContainer,
  StoryInputField
} from "@c/story-view/story-creator/story-info/StoryInfo.styled";
import {
  CheckBall,
  CheckBallInner,
  Chevron,
  DropDown,
  DropdownItem,
  DropDownMenu,
  FlexBetween,
  ThemeButton,
  FlexStart,
  SubLevelItem,
  DateSelectionField,
  ModalCountItem,
  ModalCountOverlayContainer
} from "static/styled/custom.styled";
import { ActivityDescription } from "static/styled/ActivityModal.styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert2";
import "../../../../global.css";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

const errorHandler = err => {
  swal({
    text: err,
    type: "warning"
  });
};


class NewsFreedsInfo extends Component {
  constructor(props) {
    super();
    this.state = {
      noError: false,
      selectedLevels: [],
      levelData: [],
      unsetData: false,
      subLevelSelector: false,
      activeLevel: "",
      dateErr: "",
      selectedDate: moment(),
      imageChange: false,
      enableLoad: false,
      levelTypeSelector: false,
      news_info: {
        image: "",
        date: "",
        name: "",
        grade_level: [],
        source: "",
        newsEdit: false
      }
    };
  }

  componentDidMount() {
    let currentPath = window.location.href;
    if (currentPath.includes("/create")) {
      this.setState({
        newsEdit: true
      });
    }
    let levelData = [];
    if (this.props.level && this.props.level.levels) {
      for (let i = 0; i < this.props.level.levels.length + 1; i++)
        levelData = levelData.concat({ clicked: false, levels: [] });
    }
    this.setState({
      levelData: levelData
    });
    let { newsInfo } = this.props;
    if (newsInfo !== null) {
      let date = newsInfo.date;
      let dateData = date.split("-");
      let newDate = moment();
      let currentDate = new Date();
      newDate.set({
        year: dateData[0],
        month: dateData[1] - 1,
        date: dateData[2]
      });
      let disableDate = new Date(newDate._d) >= currentDate;

      this.setState({
        selectedDate: newDate,
        selectedLevels: newsInfo.grade_level,
        disableDate: disableDate,
        news_info: {
          ...this.state.news_info,
          image: newsInfo.image,
          date: newsInfo.date,
          name: newsInfo.name,
          source: newsInfo.source
        }
      });
    }
  }

  componentWillUnmount() {
    this.unsetData();
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.newsFreeds && nextProps.newsFreeds.active_news_freed) {
      this.normalizeForState(nextProps.newsFreeds.active_news_freed);
    }
  };

  unsetData = () => {
    this.setState({
      selecetLevels: [],
      levelData: [],
      unsetData: false,
      activeLevel: "",
      disableDate: false,
      selectedDate: moment(),
      news_info: {
        image: "",
        date: "",
        name: "",
        grade_level: [],
        source: "",
        newsEdit: false
      }
    });
  };

  normalizeForState = activeNewsFreed => {
    let date = activeNewsFreed.date;
    let dateData = date.split("-");

    let newDate = moment();
    let currentDate = new Date();
    newDate.set({
      year: dateData[0],
      month: dateData[1] - 1,
      date: dateData[2]
    });
    let disableDate = new Date(newDate._d) >= currentDate;
    this.setState({
      selectedDate: newDate,
      selectedLevels: activeNewsFreed.grade_level,
      disableDate: disableDate,
      news_info: {
        ...this.state.news_info,
        image: activeNewsFreed.image,
        date: date,

        name: activeNewsFreed.name,
        source: activeNewsFreed.source
      }
    });
  };

  handleNameChange = evt => {
    this.setState({
      news_info: {
        ...this.state.news_info,
        name: evt.target.value
      }
    });
  };

  handleDescriptionChange = evt => {
    this.setState({
      news_info: {
        ...this.state.news_info,
        description: evt.target.value
      }
    });
  };
  handleSourceChange = evt => {
    this.setState({
      news_info: {
        ...this.state.news_info,
        source: evt.target.value
      }
    });
  };

  handleImageChange = e => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          enableLoad: true,
          imageChange: false,
          news_info: { ...this.state.news_info, image: reader.result }
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then(res => {
        this.setState({
          news_info: { ...this.state.news_info, image: res.file },
          enableLoad: false
        });
      });
    }
  };

  handleImageFocus = actionType => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false
      });
  };

  handleLevelTypeSelector = value => {
    this.setState({
      levelTypeSelector: value
    });
  };

  handleLevelChange = index => {
    let selectedLevels = [];
    if (index === 0) {
      let levelData = [{ levels: [] }];
      if (this.state.selectedLevels.length < 18) {
        for (let i = 1; i < this.state.levelData.length; i++) {
          selectedLevels.push("Level " + i + ":Easy");
          selectedLevels.push("Level " + i + ":Hard");
          levelData = levelData.concat({ levels: ["Easy", "Hard"] });
        }
        this.setState({
          selectedLevels: selectedLevels,
          levelData: levelData
        });
      } else {
        let levelData = [];
        for (let i = 0; i < 10; i++)
          levelData = levelData.concat({ clicked: false, levels: [] });
        this.setState({
          selectedLevels: [],
          levelData: levelData
        });
      }
    } else {
      let levelData = this.state.levelData.map((level, checkindex) => {
        if (index === checkindex) return { ...level, clicked: !level.clicked };
        return level;
      });
      this.setState({
        levelData: levelData,
        subLevelSelector: !this.state.subLevelSelector,
        activeLevel: "Level " + index
      });
    }
  };

  handleSpecLevelChange = (selectedLevelData, selectedIndex, evt) => {
    //console.log(selectedLevelData);
    if (selectedIndex === 0) {
      this.handleLevelChange(selectedIndex);
    } else {
      let selectedLevels = this.state.selectedLevels;
      let levelName = "Level " + selectedIndex;
      let subLevelsList = ["Easy", "Hard"];
      let newLevelData = this.state.levelData.map((level, index) => {
        if (index === selectedIndex && index !== 0) {
          let levels = level.levels;
          subLevelsList.forEach(subLevel => {
            if (
              selectedLevels &&
              selectedLevels.some(data => data === levelName + ":" + subLevel)
            ) {
              selectedLevels = selectedLevels.filter(selectedLevel => {
                return selectedLevel !== levelName + ":" + subLevel;
              });
              levels = levels.filter(lev => {
                return lev !== subLevel;
              });
            } else {
              if (
                !this.state.selectedLevels.some(
                  data => data === levelName + ":" + subLevel
                )
              ) {
                selectedLevels = selectedLevels.concat(
                  levelName + ":" + subLevel
                );
                levels = levels.concat(subLevel);
              }
            }
          });

          return { ...level, levels: levels };
        }

        return level;
      });
      this.setState({
        levelData: newLevelData,
        selectedLevels: selectedLevels
      });
      evt.preventDefault();
      evt.stopPropagation();
    }
  };
  // handleDateChange = date => {
  //   let selectedDate = new Date(date._d);
  //   console.log(date)
  //   let month =
  //     selectedDate.getMonth() >= 9
  //       ? selectedDate.getMonth() + 1
  //       : "0" + (selectedDate.getMonth() + 1);
  //   let day =
  //     selectedDate.getDate() < 10
  //       ? "0" + selectedDate.getDate()
  //       : selectedDate.getDate();
  //   let dateData = selectedDate.getFullYear() + "-" + month + "-" + day;
  //   let currentDate = this.props.newsFreeds.activeNewsFreed
  //     ? new Date(this.state.selectedDate._d)
  //     : new Date();

  //   if (currentDate <= selectedDate) {
  //     this.setState({
  //       selectedDate: date,
  //       news_info: { ...this.state.news_info, date: dateData },
  //     });
  //   } else {
  //     console.log(selectedDate, 'se')
  //     swal(
  //       "Oops!",
  //       "Please select date greater than or equal to current date",
  //       "warning"
  //     );
  //   }
  // };
  handleDateChange = date => {
    this.setState({
      selectedDate: moment(date).format("YYYY-MM-DD")
    });
  };

  handleSubLevelSelected = (subLevelOption, selectedIndex) => {
    let levelName = "Level " + selectedIndex;
    let subLevelList = ["Easy", "Hard"];
    let newLevelData = this.state.levelData.map((level, index) => {
      if (selectedIndex === index && index !== 0) {
        let levels = level.levels;
        subLevelList.forEach(subLevel => {
          if (subLevelOption === subLevel) {
            //console.log(this.state.selectedLevels);
            if (level.levels.includes(subLevel)) {
              let selectedLevels = this.state.selectedLevels.filter(
                selectedLevel => {
                  return selectedLevel !== levelName + ":" + subLevel;
                }
              );
              levels = levels.filter(lev => {
                return lev !== subLevel;
              });

              this.setState({
                selectedLevels: selectedLevels
              });
            } else {
              this.setState({
                selectedLevels: this.state.selectedLevels.concat(
                  levelName + ":" + subLevel
                )
              });
              levels = levels.concat(subLevel);
            }
          }
        });

        return { ...level, levels: levels };
      }
      return level;
    });
    this.setState({
      levelData: newLevelData
    });

    //console.log(this.state.selectedLevels);
  };

  handleEditBtn = () => {
    this.setState({
      newsEdit: !this.state.newsEdit
    });

    if (!this.state.newsEdit) {
      this.props.newsFreeds.setEnableNewsEdit(true);
    }
  };

  handleErrorCheck = () => {
    let state = this.state;
    let str = "";
    str += state.selectedLevels.length === 0 ? "select levels, " : "";
    str += state.news_info.image === "" ? "select image, " : "";
    //str+=(state.story_info.storyType.length===0?'Select story type, ':'');
    str += state.news_info.name === "" ? "enter news fread title" : "";
    if (str !== "") {
      errorHandler("Please " + str);
    }
    return str;
  };

  handleLevelBtn = () => {
    // debugger
    // let date = new Date(this.state.selectedDate);
    let str = this.handleErrorCheck();
    let { newsFreeds } = this.props;
    let gradeLevel = newsFreeds.active_news_grade_level;
    let level = newsFreeds.active_news_level;
    let activeLevel = gradeLevel + ":" + level;
    if (str === "") {
      newsFreeds.setEnableNewsEdit(false);
      if (!this.state.selectedLevels.some(data => data === activeLevel)) {
        swal({
          // text:
          //   "Are you sure that you want to remove the news fread from " +
          //   activeLevel +
          //   " ?",
          text:
            "Are you sure that you want to update news fread ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        }).then(result => {
          this.handleEditBtn();
          if (result.value) {
            let selectedLevels = this.state.selectedLevels.filter(level => {
              // console.log(selectedLevels)
              return level !== activeLevel;
            });
            this.props.newsFreeds.requestUpdateNewsFreed({

              ...this.state.news_info,
              grade_level: selectedLevels
            });
            this.props.history.push("/news-freads/" + gradeLevel + "/" + level);
          } else {
            this.setState({
              //selectedLevels: this.state.selectedLevels.concat(activeLevel),
              newsEdit: false
            });
            // console.log('update request if block')
            this.props.newsFreeds.requestUpdateNewsFreed({
              ...this.state.news_info,
              grade_level: this.state.selectedLevels
            });
          }
        });
      } else {

        // console.log('update request else')
        newsFreeds.requestUpdateNewsFreed({
          ...this.state.news_info,
          grade_level: this.state.selectedLevels,
          date: moment(this.state.selectedDate).format("YYYY-MM-DD")
        });
        this.handleEditBtn();
      }
    }
  };

  handleCreateBtn = () => {
    let str = this.handleErrorCheck();
    let { newsFreeds } = this.props;

    if (str === "") {
      if (newsFreeds.questions && newsFreeds.questions.length > 0) {
        newsFreeds.setEnableNewsEdit(false);
        let selectedDate = new Date(this.state.selectedDate);
        let month =
          selectedDate.getMonth() >= 9
            ? selectedDate.getMonth() + 1
            : "0" + (selectedDate.getMonth() + 1);
        let day =
          selectedDate.getDate() < 10
            ? "0" + selectedDate.getDate()
            : selectedDate.getDate();
        let dateData = selectedDate.getFullYear() + "-" + month + "-" + day;
        let activeLevel = newsFreeds.active_news_grade_level;
        let activeSubLevel = newsFreeds.active_news_level;

        newsFreeds.requestCreateNewsFreed({
          ...this.state.news_info,
          date: dateData,
          grade_level: this.state.selectedLevels
        });
        //this.unsetData();
        this.props.history.push(
          "/news-freads/" + activeLevel + "/" + activeSubLevel
        );
      } else {
        errorHandler("PLease create mcq to create news fread");
      }
    }
  };

  handleSubLevelSelector = subLevel => {};

  handleSelectedLevels = () => {
    this.handleLevelTypeSelector(!this.state.levelTypeSelector);
    let { newsFreeds } = this.props;
    if (newsFreeds.active_news_freed) {
      const levelLength = this.props.level.levels.length;
      if (this.state.selectedLevels.length === 18) {
        let levelData = [{ levels: [] }];
        for (let i = 1; i <= levelLength; i++) {
          levelData = levelData.concat({ levels: ["Easy", "Hard"] });
        }
        this.setState({
          levelData: levelData
        });
      } else {
        let selectedLevelsData = this.state.selectedLevels.sort();
        let levelData = [{ levels: [] }];
        const levelLength = this.props.level.levels.length;
        for (let i = 1; i <= levelLength; i++) {
          let levels = [];
          let activeLevel = "Level " + i;
          selectedLevelsData.forEach(level => {
            let selectedLevel = level.split(":");
            if (selectedLevel[0] === activeLevel) {
              levels.push(selectedLevel[1]);
            }
          });
          levelData = levelData.concat({ levels: levels });
        }
        this.setState({
          levelData: levelData
        });
      }
    }
  };

  render() {
    let { state } = this;
    let { newsFreeds } = this.props;
    let currentPath = window.location.href;
    const {permission} = this.props.onboard;
    const {user} = this.props.onboard;

    return (
      <NewsFreedsInfoContainer>
        <NewsFreedsDescriptionInfoContainer
          height={this.state.newsEdit ? "200px" : "160px"}
        >
          {this.state.news_info.image === "" ? (
            <ActivityUploadImageContainer width="180px">
              <ActivityUploadImageButton type="button">
                <img
                  src={require("static/img/cloud-upload-icon.png")}
                  alt="Upload"
                />
                <span>Upload Image </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={(e) => this.handleImageChange(e)}
              />
            </ActivityUploadImageContainer>
          ) : this.state.enableLoad ? (
            <ActivityUploadImageContainer width="180px">
              <ImageLoader>
                {" "}
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </ActivityUploadImageContainer>
          ) : (
            <ActivityUploadImageContainer
              width="180px"
              onMouseOver={() => this.handleImageFocus("over")}
              onMouseOut={() => this.handleImageFocus("out")}
            >
              <ImageContainer src={this.state.news_info.image} />
              {state.newsEdit ? (
                <ChangeImageButton
                  top={this.state.imageChange ? "84%" : "100%"}
                  opacity={this.state.imageChange ? 1 : 0}
                >
                  Change Image
                </ChangeImageButton>
              ) : null}
              {state.newsEdit ? (
                <SelectFileIn
                  type="file"
                  onChange={(e) => this.handleImageChange(e)}
                />
              ) : null}
            </ActivityUploadImageContainer>
          )}
          <ActivityDescription height="auto">
            {!state.newsEdit ? (
              <StorySectionLabelsContainer>
                <NewsDescriptionLabel>
                  <span>
                    {this.state.selectedLevels.length + " "} Levels Selected |{" "}
                    {this.props.newsFreeds.active_news_grade_level}(
                    {this.props.newsFreeds.active_news_level})
                  </span>
                  <span> Source: {this.state.news_info.source}</span>
                  {user.read_only == false &&
                    Permission.getPermission(
                      permission,
                      "change_newsfread"
                    ) && (
                      <img
                        src={require("static/img/editicon.png")}
                        alt="edit icon"
                        className="image-fluid"
                        style={{ position: "absolute", top: 0, left: "220px" }}
                        onClick={this.handleEditBtn}
                      />
                    )}
                </NewsDescriptionLabel>
              </StorySectionLabelsContainer>
            ) : (
              <StorySectionLabelsContainer>
                <FlexBetween
                  style={{ width: "80%", justifyContent: "flex-start" }}
                >
                  <DropDown
                    minWidth="160px"
                    active={this.state.levelTypeSelector}
                    onBlur={() => this.handleLevelTypeSelector(false)}
                    tabIndex="1"
                  >
                    <div
                      style={{ fontSize: "14px", height: "24px" }}
                      onClick={this.handleSelectedLevels}
                    >
                      {this.state.selectedLevels.length >= 18
                        ? "All "
                        : this.state.selectedLevels.length + " "}{" "}
                      Levels Selected
                      <Chevron>
                        <img
                          src={require("static/img/downarrowicon.png")}
                          alt="down-arrow"
                        />
                      </Chevron>
                    </div>
                    <DropDownMenu
                      maxHeight="150px"
                      active={this.state.levelTypeSelector}
                      color="#fff"
                      style={{ padding: "0px" }}
                    >
                      {this.state.levelData.map((levelData, index) => (
                        <DropdownItem key={index}>
                          <FlexStart
                            style={{
                              width: "100%",
                              padding: "6px 8px",
                              position: "relative",
                              borderBottom: "solid 1px #e6e6e6",
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => this.handleLevelChange(index)}
                          >
                            <CheckBall
                              onClick={(evt) =>
                                this.handleSpecLevelChange(
                                  levelData,
                                  index,
                                  evt
                                )
                              }
                              style={{ marginRight: "6px" }}
                              active={levelData.levels.length > 0}
                            >
                              <CheckBallInner
                                active={
                                  levelData.levels.length > 0 ||
                                  this.state.selectedLevels.length === 18
                                }
                              />
                            </CheckBall>
                            {index === 0
                              ? this.state.selectedLevels.length === 18
                                ? "Unselect All"
                                : "Select All"
                              : "Level " + index}
                            {index !== 0 && (
                              <Chevron
                                cross={
                                  this.state.activeLevel === "Level " + index &&
                                  this.state.subLevelSelector
                                    ? "rotate(0deg)"
                                    : "rotate(-90deg)"
                                }
                              >
                                <img
                                  src={require("static/img/downarrowicon.png")}
                                  alt="down-arrow"
                                />
                              </Chevron>
                            )}

                            {levelData.name}
                          </FlexStart>
                          {this.state.activeLevel === "Level " + index &&
                          index !== 0 &&
                          levelData.clicked ? (
                            <div>
                              <SubLevelItem
                                style={{
                                  backgroundColor: "#faf7ff",
                                  width: "100%",
                                  padding: "6px 8px 6px 32px",
                                  justifyContent: "flex-start",
                                  borderBottom: "solid 1px #e6e6e6",
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => {
                                  this.handleSubLevelSelected("Easy", index);
                                }}
                              >
                                <CheckBall
                                  style={{ marginRight: "6px" }}
                                  active={levelData.levels.includes("Easy")}
                                >
                                  <CheckBallInner
                                    active={levelData.levels.includes("Easy")}
                                  />
                                </CheckBall>{" "}
                                Easy
                              </SubLevelItem>
                              <SubLevelItem
                                style={{
                                  backgroundColor: "#faf7ff",
                                  width: "100%",
                                  padding: "6px 8px 6px 32px",
                                  justifyContent: "flex-start",
                                  borderBottom: "solid 1px #e6e6e6",
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => {
                                  this.handleSubLevelSelected("Hard", index);
                                }}
                              >
                                <CheckBall
                                  style={{ marginRight: "6px" }}
                                  active={levelData.levels.includes("Hard")}
                                >
                                  <CheckBallInner
                                    active={levelData.levels.includes("Hard")}
                                  />
                                </CheckBall>{" "}
                                Hard
                              </SubLevelItem>
                            </div>
                          ) : null}
                        </DropdownItem>
                      ))}
                    </DropDownMenu>
                  </DropDown>
                  <StoryInputField
                    height="32px"
                    primary
                    placeholder="Source"
                    border={
                      !state.newsEdit ? "none" : "solid 1px rgba(40,50,112,0.1)"
                    }
                    style={{ marginLeft: "14px" }}
                    fontSize={this.state.newsEdit ? "14px" : "18px"}
                    id="activityPackName"
                    value={state.news_info.source}
                    width="50%"
                    left={state.newsEdit ? "6px" : "0px"}
                    margin={state.newsEdit ? "4px 0px " : "0px"}
                    onChange={this.handleSourceChange}
                    disabled={!this.state.newsEdit}
                  />
                </FlexBetween>

                {!currentPath.includes("/create") ? (
                  <ThemeButton
                    primary
                    onClick={this.handleLevelBtn}
                    style={{ width: "200px" }}
                  >
                    Save Changes
                  </ThemeButton>
                ) : (
                  <ThemeButton
                    primary
                    onClick={this.handleCreateBtn}
                    style={{ width: "200px" }}
                  >
                    Create News Fread
                  </ThemeButton>
                )}
              </StorySectionLabelsContainer>
            )}

            <div id="news-freeds-date">
              <DateSelectionField
                style={{
                  overflow: "hidden",
                  width: "160px",
                  margin: "10px 0px",
                }}
              >
                <img
                  src={require("static/img/date-icon.png")}
                  alt="startDate"
                />
                <DatePicker
                  style={{ width: "100%" }}
                  // selected={this.state.selectedDate}
                  selected={
                    moment.isMoment(this.state.selectedDate)
                      ? this.state.selectedDate.toDate()
                      : this.state.selectedDate
                  }
                  onChange={this.handleDateChange}
                  disabled={!this.state.newsEdit || this.state.disabled}
                  minDate={new Date()}
                />
              </DateSelectionField>
            </div>
            <StoryInputField
              height="40px"
              primary
              placeholder="Add New Fread Title "
              border={
                !state.newsEdit ? "none" : "solid 1px rgba(40,50,112,0.1)"
              }
              fontSize={this.state.newsEdit ? "14px" : "16px"}
              value={state.news_info.name}
              width={state.newsEdit ? "75%" : "100%"}
              left={state.newsEdit ? "6px" : "0px"}
              margin={state.newsEdit ? "4px 0px " : "0px"}
              onChange={this.handleNameChange}
              disabled={!this.state.newsEdit}
            />
          </ActivityDescription>
          {!this.state.newsEdit && newsFreeds.active_news_freed && (
            <ModalCountOverlayContainer>
              <ModalCountItem>
                <span> {newsFreeds.active_news_freed.reading_count}</span>
                <i className="fa fa-sticky-note-o" />
              </ModalCountItem>
              <ModalCountItem>
                <span> {newsFreeds.active_news_freed.completed_count} </span>
                <i className="fa fa-bullseye" />
              </ModalCountItem>
              <ModalCountItem>
                <span>{newsFreeds.active_news_freed.accuracy} </span>
                <i className="fa fa-tachometer" />
                {/*<img src={require("static/img/page-check.png")} alt="pagecheck"/>*/}
              </ModalCountItem>
            </ModalCountOverlayContainer>
          )}
        </NewsFreedsDescriptionInfoContainer>
      </NewsFreedsInfoContainer>
    );
  }
}
export default connectStore({
  newsFreeds,
  level,onboard
})(NewsFreedsInfo)