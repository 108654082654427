import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connectStore } from "redux-box";
import { module as inviteCode } from "@st/inviteCode";
import { module as product } from "@st/product";
import { module as onboard } from "@st/onboard";
import Select from "react-select";
import CheckBox from "./CheckBox";
import {
  DropDownContainer,
  DropDownTitle,
  DatePickerContainer,
} from "./SchoolGuidedPathEnable.styled";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  DateSelector,
  DateSelectorIcon,
} from "../../flive-code-view/renewalold-school/RenewalOldSchool.styled";

function SchoolGuidedPathEnable(props) {
  const [gradeOption, setGradeOption] = useState([]);
  const [dropdownProduct, setProduct] = useState("");
  const [serverGradeOption, setServerGradeOption] = useState([]);
  const [schoolGuidedPathEnableData, setSchoolGuidedPathEnableData] = useState({
    isEnableSchoolGuidedPath: false,
    selectedGrade: [],
    // selectedDate : moment().format("YYYY-MM-DD")
    selectedDate: "",
  });

  useEffect(() => {
    let { schoolEditInfo } = props.inviteCode;
    if (schoolEditInfo) {
      props.inviteCode.getSchoolGrade(schoolEditInfo.id);
      if (schoolEditInfo.school_guided_path) {
        let isEnable =
          schoolEditInfo.school_guided_path.grade_ids.length > 0 ? true : false;
        setServerGradeOption(schoolEditInfo.school_guided_path.grade_ids);
        setSchoolGuidedPathEnableData({
          ...schoolGuidedPathEnableData,
          isEnableSchoolGuidedPath: isEnable,
          selectedDate: schoolEditInfo.school_guided_path.start_date,
        });
        setProduct(
          props.inviteCode.schoolEditInfo.school_guided_path.guided_path
            ? props.product.product_list
                .map((pr) => {
                  return { label: pr.name, value: pr.id };
                })
                .filter(
                  (pr) =>
                    pr.value ===
                    props.inviteCode.schoolEditInfo.school_guided_path
                      .guided_path
                )[0]
            : ""
        );
        console.log(
          "product from Backend",
          props.inviteCode.schoolEditInfo.school_guided_path.guided_path
            ? props.inviteCode.schoolEditInfo.school_guided_path.guided_path
            : ""
        );

        updateSchoolGuidedPathState({
          isEnableSchoolGuidedPath:
            isEnable && !schoolEditInfo.school_guided_path.is_deleted,
          selectedDate: schoolEditInfo.school_guided_path.start_date,
          selectedGrade: schoolEditInfo.school_guided_path.grade_ids,
          guided_path:
            props.inviteCode.schoolEditInfo.school_guided_path.guided_path,
        });
      }
    }

    return () => {
      props.inviteCode.clearSchoolGuidedPath();
    };
  }, []);

  useEffect(() => {
    if (props.inviteCode.schoolGrade.length > 0) {
      let tempGradeOption = [];
      if (serverGradeOption.length > 0) {
        let tempGradeSelectedOption = [];
        props.inviteCode.schoolGrade.forEach((element) => {
          if (serverGradeOption.includes(element.grade)) {
            tempGradeSelectedOption.push({
              value: element.grade,
              label: element.name,
            });
          } else {
            tempGradeOption.push({ value: element.grade, label: element.name });
          }
        });
        setSchoolGuidedPathEnableData({
          ...schoolGuidedPathEnableData,
          selectedGrade: tempGradeSelectedOption,
        });
        setGradeOption(tempGradeOption);
      } else {
        props.inviteCode.schoolGrade.forEach((element) => {
          tempGradeOption.push({ value: element.grade, label: element.name });
        });
        setGradeOption(tempGradeOption);
      }
    }
  }, [props.inviteCode.schoolGrade, serverGradeOption]);

  const onDatepickerRef = (el) => {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  };

  const updateSchoolGuidedPathState = (data) => {
    /* 
            updateSchoolGuidedPathState update the form data as per the server requirement
            so whenever we are updating schoolGuidedPathEnableData value simultaneously need to update redux state as well
            so we can use those value in higher order component
        */
    props.inviteCode.setSchoolGuidedPathFormData(data);
  };

  const handleCheckboxChange = (event) => {
    setSchoolGuidedPathEnableData({
      ...schoolGuidedPathEnableData,
      isEnableSchoolGuidedPath: event.target.checked,
    });
    updateSchoolGuidedPathState({
      isEnableSchoolGuidedPath: event.target.checked,
    });
  };

  const handleEnableDateChange = (date) => {
    setSchoolGuidedPathEnableData({
      ...schoolGuidedPathEnableData,
      selectedDate: moment(date).format("YYYY-MM-DD"),
    });
    updateSchoolGuidedPathState({
      selectedDate: moment(date).format("YYYY-MM-DD"),
    });
  };

  const handleGradeChange = (event) => {
    if (serverGradeOption.length > 0) {
      let removeItem = schoolGuidedPathEnableData.selectedGrade.filter(
        (x) => !event.includes(x)
      );
      removeItem.forEach((ele) => {
        if (serverGradeOption.includes(ele.value)) {
          setGradeOption([...gradeOption, ...ele]);
        }
      });
    }
    setSchoolGuidedPathEnableData({
      ...schoolGuidedPathEnableData,
      selectedGrade: event,
    });
    let gradeArr = event.map((ele) => ele.value);
    updateSchoolGuidedPathState({ selectedGrade: gradeArr });
  };

  const handleProductChange = (tag) => {
    setProduct(tag);
    updateSchoolGuidedPathState({ guided_path: tag.value });
    console.log("product selected", tag);
  };

  return (
    props.inviteCode.schoolEditInfo && (
      <div>
        <div>
          <label>
            <CheckBox
              checked={schoolGuidedPathEnableData.isEnableSchoolGuidedPath}
              onChange={handleCheckboxChange}
              disabled={
                props.inviteCode.schoolEdit
                  ? !props.inviteCode.schoolEdit
                  : true
              }
            />
            <span style={{ marginLeft: 8 }}>Enable Guided Path</span>
          </label>
        </div>
        {schoolGuidedPathEnableData.isEnableSchoolGuidedPath && (
          <div>
            <div style={{ marginTop: 10, marginBottom: 20 }}>
              <h6>Products</h6>
              <Select
                isDisabled={
                  props.inviteCode.schoolEdit
                    ? !props.inviteCode.schoolEdit
                    : true
                }
                onChange={handleProductChange}
                placeholder="Select Product"
                styles={{
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: "rgba(0, 0, 0, 0.2)",
                  }),
                }}
                value={dropdownProduct}
                options={
                  props.product.product_list &&
                  props.product.product_list.map((pr) => {
                    return { label: pr.name, value: pr.id };
                  })
                }
              />
            </div>
            <DropDownContainer>
              <DropDownTitle>SCHOOL GRADE</DropDownTitle>
              <Select
                styles={{
                  placeholder: (provided, state) => ({
                    ...provided,
                    color: "rgba(0, 0, 0, 0.2)",
                  }),
                }}
                placeholder={"select school grade"}
                value={schoolGuidedPathEnableData.selectedGrade}
                isDisabled={
                  props.inviteCode.schoolEdit
                    ? !props.inviteCode.schoolEdit
                    : true
                }
                onChange={handleGradeChange}
                options={gradeOption}
                isMulti={true}
              />
            </DropDownContainer>
            <DatePickerContainer>
              <h6>Start Date</h6>
              <DateSelector
                style={{ overflow: "hidden", border: "1px solid #a349c9" }}
              >
                <DateSelectorIcon className="fa fa-calendar" />
                <DatePicker
                  selected={schoolGuidedPathEnableData.selectedDate}
                  selectsStart
                  startDate={schoolGuidedPathEnableData.selectedDate}
                  onChange={handleEnableDateChange}
                  minDate={moment().toDate()}
                  placeholderText="Select Enable Date"
                  disabled={
                    props.inviteCode.schoolEdit
                      ? !props.inviteCode.schoolEdit
                      : true
                  }
                  ref={(el) => onDatepickerRef(el)}
                />
              </DateSelector>
            </DatePickerContainer>
          </div>
        )}
      </div>
    )
  );
}

export default withRouter(
  connectStore({
    inviteCode,
    onboard,
    product,
  })(SchoolGuidedPathEnable)
);
