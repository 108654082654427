import React, { Component } from "react";
import swal from 'sweetalert2';
import { ActivityAddHeaderContainer } from "@sc/ActivityModal.styled";
import { ThemeButton, FlexBetween, AudioThemeButton, SubHeading } from "@sc/custom.styled";
import {
  AddMediaPopUpContainer,
  BrowseContainer,
  DeleteItemContainer,

  MediaSelectionItem,
  MediaSelectorMenu,
  PhotoAlbumContainer,
  PhotoAlbumItem,
  PhotoAlbumListContainer,
  PhotoImage,
  UploadFileContainer,
  UploadFileLabelContainer,
  UploadLabel
} from "@c/activity-view/activity-creator/media-editor/MediaEditor.styled";
import { uploadFile, uploadEpubFile } from "services/api/file";
import { ActivityInputField } from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { connectStore } from "redux-box";
import { module as story } from "store/story";
import {
  ActivityUploadImageButton, ChangeImageButton, ImageContainer, SelectFileIn,
  UploadActivityImageContainer
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { ImageLoader, ImageURLContainer } from "static/styled/custom.styled";


class StoryMediaEditor extends Component {
  constructor(props) {
    super();
    this.state = {
      imageChange: false,
      enableLoad: false,
      content_type: "image",
      active_audio_file: null,
      thumbnail_image: '',
      current_audio: '',
      media: [],
      youtube_url: "",
      audioFileName: '',
      new: false,

    };
  }

  componentWillMount() {
    this.reflectMedia(this.props)
  }

  componentWillReceiveProps(props) {
    this.reflectMedia(props)
  }

  setActiveAudio = (media, evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (media.media_url.length > 0) {
      let audioFileName = media.media_url.split("/");
      this.setState({
        audioFileName: audioFileName[audioFileName.length - 1]
      })
    }
    else {
      this.setState({
        audioFileName: ''
      })
    }
    this.setState({
      active_audio_file: media,
      thumbnail_image: media.thumbnail_url,
      imageChange: true,
      current_audio: media.media_url
    })
  };

  reflectMedia = (props) => {
    if (props.story.current_section_action === "create") {
      //new section to be created
    }
    else {
      //const urls = props.story.active_section.media && props.story.active_section.media.map( item => item.media_url);
      this.setState({
        media: props.story.active_section.media,
        content_type: props.story.active_section.content_type !== "text" ? props.story.active_section.content_type : 'image',
        strict: props.story.active_section.content_type !== 'text'
      })
    }
  }


  handleRemove = url => {
    let media = this.state.media.filter(item => item.media_url !== url);
    this.setState({
      media
    });
  };

  setMediaType = option => {
    if (this.state.content_type !== option.type && this.state.media.length > 0) {
      swal({
        title: 'Are you sure?',
        text: "You have already uploaded some files in other format! Changing file type would reset previous uploads!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'red',
        cancelButtonColor: 'skyblue',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.setState({
            content_type: option.type,
            media: []
          });
        }
      })
    }
    else {
      this.setState({
        content_type: option.type,
      });
    }
  };

  onYoutubeLinkAdd = e => {
    if (e.key === "Enter") {
      this.setState({
        media: this.state.media.concat({ thumbnail_url: '', media_url: e.target.value }),
        youtube_url: ""
      });
    }
  };

  onYoutubeBtn = (e) => {
    if (this.state.youtube_url && this.state.youtube_url.length > 0)
      this.setState({
        media: this.state.media.concat({ thumbnail_url: '', media_url: this.state.youtube_url }),
        youtube_url: ""
      });

  };

  handleUpload = e => {
    let i = 0;
    //let content_type=this.state.content_type;
    if (e.target.files) {
      while (i < e.target.files.length) {
        // debugger;
        //console.log(mediaFile);
        if (this.state.content_type !== 'epub')
          uploadFile(e.target.files[i]).then(res => {
            this.setState({
              media: this.state.media.concat({ thumbnail_url: '', media_url: res.file }),
            });
          });
        else {
          uploadEpubFile(e.target.files[i]).then(res => {
            this.setState({
              media: this.state.media.concat({ thumbnail_url: '', media_url: res.file }),
            });
          });
        }
        i++;
      }
    }
  };




  onDone = () => {
    const { story } = this.props;

    if (story.current_section_action === 'create') {
      this.createSection();
      this.props.story.resetNewStorySections();
    }
    else
      this.updateSection()

    story.setStoryEditorType('');
  }

  updateSection = () => {
    //const media = this.state.media.map(item => ({thumbnail_url:"",  media_url: item}))

    const data = {
      media: this.state.media,
      content_type: this.state.media.length !== 0 ? this.state.content_type : 'text'
    }
    this.props.story.requestUpdateStorySection(data)
  }

  createSection = () => {
    //const media = this.state.media.map(item => ({thumbnail_url:"",  media_url: item}))

    const data = {
      content_type: this.state.media.length !== 0 ? this.state.content_type : 'text',
      media: this.state.media,
    }
    this.props.story.requestCreateStorySection(
      data,
      this.props.story.active_story.id,
    )
  }

  getFileTypes = () => {
    switch (this.state.content_type) {
      case "photo":
        return ".jpg, .jpeg, .png, .gif"
      case "video":
        return ".mp4, .avi, .mpeg, .wmv, .mov"
      case "audio":
        return ".mp3, .ogg"
      case "pdf":
        return ".pdf"
      case "epub":
        return ".epub"
      default:
        return ".jpg, .jpeg, .png, .gif"
    }
  }

  handleThumbnailImageFocus = actionType => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false
      });
  };

  _handleThumbnailImageChange = e => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal('Oops!', 'File size greater than 5MB ', 'warning')
    }
    else {
      //let resFile='';
      reader.onloadend = () => {
        this.setState({
          imageChange: false,
          enableLoad: true,
          thumbnail_image: reader.result,

        });
      };
      reader.readAsDataURL(file);
      uploadFile(file)
        .then(res => {
          let media = this.state.media.map((media) => {
            if (media.media_url === this.state.current_audio)
              return ({ ...media, thumbnail_url: res.file });
            return media;
          });
          this.setState({
            thumbnail_image: res.file,
            media: media,
            enableLoad: false
          })
        });
    }


  };

  handleAudioUploadBtn = () => {
    this.setState({
      thumbnail_image: '',
      audioFileName: '',
      current_audio: ''

    })
    if (this.state.active_audio_file) {
      this.setState({
        active_audio_file: null,

      })

    }
  }

  handleAudioUpload = (e) => {

    if (e.target.files && e.target.files.length > 0) {
      let fileSize = e.target.files[0].size / 1024 / 1024;
      if (fileSize > 2) {
          swal('Oops!', 'File size greater than 5MB ', 'warning')
      }
      else {
      this.setState({
        audioFileName: e.target.files.length > 0 ? e.target.files[0].name : '',
      });

      uploadFile(e.target.files[0]).then(res => {
        this.setState({

          media: this.state.media.concat({ thumbnail_url: '', media_url: res.file }),
          current_audio: res.file,
        });
      });}

    }

    this.setState({
      thumbnail_image: '',
      imageChange: false,
    })

  };

  getThumbnails = () => {
    if (this.state.content_type === "youtube") {
      return this.state.media.map((media, i) => {
        const arr = media.media_url.split("/");
        const last = arr && arr[arr.length - 1].split("=");
        const id = last && last[last.length - 1];

        return (
          <PhotoAlbumItem key={media.media_url}>
            <PhotoImage
              src={`https://img.youtube.com/vi/${id}/0.jpg`}
              alt="Photo item"
            />
            <DeleteItemContainer
              src={require("static/img/photo-cancel-icon.png")}
              onClick={() => this.handleRemove(media.media_url)}
            />
          </PhotoAlbumItem>
        );
      });
    }

    else if (this.state.content_type === "image") {
      return this.state.media.map((media, i) => {
        return (
          <PhotoAlbumItem key={media.media_url}>
            <PhotoImage
              src={media.media_url}
              alt="Photo item"
            />
            <DeleteItemContainer
              src={require("static/img/photo-cancel-icon.png")}
              onClick={() => this.handleRemove(media.media_url)}
            />
          </PhotoAlbumItem>
        );
      })
    }
    else {
      return this.state.media.map((media, i) => {
        return (
          <PhotoAlbumItem key={media.media_url}>
            <a href={media.media_url} target="_blank">
              <PhotoImage
                src="http://www.free-icons-download.net/images/file-icon-28038.png"
                alt="Photo item"
              />
            </a>
            <DeleteItemContainer
              src={require("static/img/photo-cancel-icon.png")}
              onClick={() => this.handleRemove(media.media_url)}
            />
          </PhotoAlbumItem>
        );
      })
    }
  };

  render() {
    let fileTypes = [
      {
        name: "Photos",
        type: "image",
        fontIcon: "fa fa-camera",
        fileType: "image/*"
      },
      {
        name: "Videos",
        type: "video",
        fontIcon: "fa fa-video-camera",
        fileType: "video/*"
      },
      { name: "File", type: "file", fontIcon: "fa fa-file", fileType: "/*" },
      {
        name: "Audio",
        type: "audio",
        fontIcon: "fa fa-volume-up",
        fileType: "audio/*"
      },
      {
        name: "Youtube",
        type: "youtube",
        fontIcon: "fa fa-youtube",
        fileType: "youtube"
      }
    ];

    let fileSubTypes = [
      {
        name: "PDF",
        type: "pdf",
        fontIcon: "fa fa-file-pdf-o",
        fileType: "pdf/*"
      },
      {
        name: "Epub",
        type: "epub",
        fontIcon: "fa fa-file-zip-o",
        fileType: "epub/*"
      }
    ]


    if (this.state.strict) {

      if (this.state.content_type === 'pdf' || this.state.content_type === 'epub')
        fileTypes = [{ name: "File", type: "file", fontIcon: "fa fa-file", fileType: "/*" }];
      else
        fileTypes = fileTypes.filter(cat => {
          return cat.type === this.state.content_type
        });
    }


    let fileSelectionItems = fileSubTypes.map((option, i) => (
      <MediaSelectionItem
        style={{ marginRight: '18px' }}
        key={i}
        textColor={
          (option.type === this.state.content_type)
            ? "#ff8a23"
            : "rgba(38, 54, 81,0.4)"
        }
        onClick={() => this.setMediaType(option)}
      >
        <i className={option.fontIcon} />
        <span> {option.name} </span>
      </MediaSelectionItem>
    ));

    let mediaSelectionItems = fileTypes.map((option, i) => (
      <MediaSelectionItem
        key={i}
        textColor={
          (option.type === this.state.content_type) ||
            ((this.state.content_type === 'epub' || this.state.content_type === 'pdf')
              && option.type === 'file')
            ? "#ff8a23"
            : "rgba(38, 54, 81,0.4)"
        }
        onClick={() => this.setMediaType(option)}
      >
        <i className={option.fontIcon} />
        <span> {option.name} </span>
      </MediaSelectionItem>
    ));

    const section = this.props.story.active_section || {};

    return (
      <AddMediaPopUpContainer>
        <ActivityAddHeaderContainer>
          <span>Input Media </span>
          <ThemeButton primary onClick={this.onDone}>
            Done
          </ThemeButton>
        </ActivityAddHeaderContainer>

        <ActivityInputField
          disabled
          value={this.props.title || section.name}
          id="mediaOptional"
          type="text"
          placeholder="Media Title (Optional)"
        />
        <MediaSelectorMenu>{mediaSelectionItems}</MediaSelectorMenu>
        {(this.state.content_type === 'file' || this.state.content_type === 'epub' || this.state.content_type === 'pdf') &&
          <div>
            <SubHeading style={{ marginBottom: 0 }}> Select File Type </SubHeading>
            <MediaSelectorMenu style={{ justifyContent: 'flex-start' }}>{fileSelectionItems}</MediaSelectorMenu>
          </div>}
        {/* //  this is the option valid only for image, epub, pdf and videos*/}
        {(this.state.content_type === "image" || this.state.content_type === 'video'
          || this.state.content_type === 'pdf' || this.state.content_type === 'epub') &&
          <UploadFileContainer>
            <UploadFileLabelContainer>
              <i className="fa fa-cloud-upload" />
              <UploadLabel>
                Drag Here or
                {(this.state.content_type !== 'pdf' || this.state.content_type !== 'epub') ?
                  <BrowseContainer>
                    <input
                      type="file"
                      accept={this.getFileTypes()}
                      onChange={this.handleUpload}
                      multiple
                    />
                    <span> Browse</span>
                  </BrowseContainer> :
                  <BrowseContainer>
                    <input
                      type="file"
                      accept={this.getFileTypes()}
                      onChange={this.handleUpload}
                    />
                    <span> Browse</span>
                  </BrowseContainer>}
                &nbsp; to Upload
              </UploadLabel>
            </UploadFileLabelContainer>
          </UploadFileContainer>}
        {this.state.content_type === 'audio' && <div>
          <FlexBetween style={{ marginBottom: '14px' }}>
            <AudioThemeButton primary style={{ width: '45%' }}>
              <input
                type="file"
                accept={this.getFileTypes()}
                onChange={this.handleAudioUpload}
                onClick={this.handleAudioUploadBtn}
              />
              Upload Audio
                    </AudioThemeButton>
            <div style={{ width: '50%', fontSize: '12px' }}>
              {this.state.audioFileName}
            </div>
          </FlexBetween>
          {this.state.thumbnail_image === '' ? (
            <UploadActivityImageContainer height="100px">
              <ActivityUploadImageButton type="button">
                <div> <img src={require("static/img/cloud-upload-icon.png")} style={{ width: '18px', height: '18px' }} alt="cloud-upload" />
                </div>
                <span>Upload Thumbnail  </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={e => this._handleThumbnailImageChange(e)}
              />
            </UploadActivityImageContainer>
          ) : this.state.enableLoad ?
              <UploadActivityImageContainer height="100px" marginBottom="14px">
                <ImageLoader > <img alt="loading" width="30" height="30" src="http://photokonkurs.russia.travel/images/icons/preloader.gif" />
                </ImageLoader>
              </UploadActivityImageContainer> :
              <UploadActivityImageContainer
                height="100px"
                onMouseOver={() => this.handleThumbnailImageFocus("over")}
                onMouseOut={() => this.handleThumbnailImageFocus("out")}
              >
                <ImageContainer src={this.state.thumbnail_image} />
                <ChangeImageButton
                  top={this.state.imageChange ? "83%" : "100%"}
                  style={this.state.imageChange ? { top: '72%' } : {}}
                  opacity={this.state.imageChange ? 1 : 0}
                >
                  Change Thumbnail
                        </ChangeImageButton>
                <SelectFileIn
                  type="file"
                  onChange={e => this._handleThumbnailImageChange(e)}
                />
              </UploadActivityImageContainer>}

        </div>}
        {this.state.content_type === 'youtube' &&
          <div>
            <ImageURLContainer>
              <input
                type="text"
                placeholder="Enter Video URL"
                value={this.state.youtube_url}
                onChange={(e) => this.setState({ youtube_url: e.target.value })}
                onKeyPress={this.onYoutubeLinkAdd}
              />
              <img
                src={require("static/img/cloud-upload.png")}
                alt="img-upload"
                onClick={this.onYoutubeBtn}
              />
            </ImageURLContainer>
          </div>}

        <PhotoAlbumContainer>
          <span> {this.state.media && this.state.media.length} &nbsp;Items</span>
          <PhotoAlbumListContainer>
            {this.getThumbnails()}
          </PhotoAlbumListContainer>
        </PhotoAlbumContainer>
      </AddMediaPopUpContainer>
    );
  }
}
export default connectStore({
  story
})(StoryMediaEditor)