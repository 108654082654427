import { createActions, using } from "redux-box";

export default createActions({
  requestSchoolList: using(),
  requestProjectList: using(),
  requestSlotList: using(),
  requestClassList: using("schoolId"),
  requestSectionList: using("data"),
  createRandomInviteCode: using("data"),
  requestVerifyInviteCodeCsv: using("data"),
  unsetInviteCodeCsvDetail: using("data"),
  requestSchoolCodeList: using(),
  createSchoolCode: using("data"),
  requestSchoolCodesByOffset: using("offset"),
  requestSchoolSearch: using("data"),
  setSelectedSchool: using("data"),
  requestUpdateSchool: using("data"),
  getSchoolList: using("offset"),
  createSchool: using("schoolData"),
  enableSchoolEdit: using("value"),
  setEditSchool: using("school"),
  updateSchool: using("schoolId , schoolData"),
  createClass: using("classData"),
  getClass: using("classData"),
  setSearchStringFun: using("value"),
  getReportData: using("schoolInfo"),
  clearReportData: using(),
  getSchoolGrade : using("schoolId"),
  setSchoolGuidedPathFormData : using("value"),
  updateSchoolGuidedPath : using("schoolGuidedPathObj"),
  clearSchoolGuidedPath : using()
});
