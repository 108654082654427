import React, { Component} from "react";
import {
  ActivityContinueButtonContainer,
  ActivityMainFormContainer,
  CreateActivityContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {
  RCNameInput,
  CreateRCButton,
  PRTextArea,
  PRShortTextArea,
  Dropdown,
} from "./SchoolGuidedPathCreationForm.styled";
import { ThemeButton } from "@sc/custom.styled";

import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import { module as user } from "@st/user";

class SchoolGuidedPathCreationForm extends Component {
  constructor(props) {
    super();
    this.state = {
      schoolGuidedPathFormData: {
        name: "",
        description: "",
        selectedGrade: {},
        short_description: "",
        section: [],
        selectedSection: "",
        id: "",
        school_id: null,
      },
    };
  }
  componentDidMount() {
    const { activity } = this.props;
    const { user } = this.props.onboard;
    // console.log("props:", this.props);

    let selectedGrade = activity.grades.filter(
      (ele) => ele.id === this.props.schoolGuidedPath.active_grade
    );
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        selectedGrade: selectedGrade[0],
      },
    });

    let { unitEditInfo } = this.props.schoolGuidedPath;
    if (unitEditInfo && unitEditInfo.id) {
      this.setUnitData();
    } else {
      this.props.schoolGuidedPath.setUnitEdit(true);
    }
    // console.log("selected grade:", this.props.schoolGuidedPath.active_grade);
    this.fetchProduct();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.sections !== this.props.user.sections) {
      this.setState({
        schoolGuidedPathFormData: {
          ...this.state.schoolGuidedPathFormData,
          section: this.props.user.sections,
        },
      });
    }
  }

  fetchSection = (school_id) => {
    let payload = {
      school: school_id,
      grade: this.props.schoolGuidedPath.active_grade,
    };

    this.props.user.getSections(payload);

  };
  fetchProduct = async () => {

    // make the API call with the authorization header
    let product = this.props.schoolGuidedPath.gpProduct;
    // if(product){
    let sectionIdList = Object.keys(product);

    let school_id = product[sectionIdList[0]].sections[0].school;
    // console.log("sch:",school_id);
    this.fetchSection(school_id);
    // console.log(product);
  };

  componentWillUnmount() {
    this.props.schoolGuidedPath.setUnitEditInfo(null);
    this.props.schoolGuidedPath.setUnitEdit(false);
  }

  setUnitData = () => {
    let { unitEditInfo } = this.props.schoolGuidedPath;
    const { activity } = this.props;
    let selectedGrade = activity.grades.filter(
      (ele) => ele.id === this.props.schoolGuidedPath.active_grade
    );
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        name: unitEditInfo.name,
        description: unitEditInfo.description,
        short_description: unitEditInfo.short_description,
        selectedGrade: selectedGrade[0],
        selectedSection: unitEditInfo.school_class,
        id: unitEditInfo.id,
      },
    });
  };

  handleName = (evt) => {
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        name: evt.target.value,
      },
    });
  };

  handleShortDescription = (evt) => {
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        short_description: evt.target.value,
      },
    });
  };

  handleDescription = (evt) => {
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        description: evt.target.value,
      },
    });
  };
  handleSection = (evt) => {
    this.setState({
      schoolGuidedPathFormData: {
        ...this.state.schoolGuidedPathFormData,
        selectedSection: evt.target.value,
      },
    });
  };
  handleEditBtn = () => {
    this.props.schoolGuidedPath.setUnitEdit(true);
  };

  handleUpdateBtn = () => {
    const { schoolGuidedPathFormData } = this.state;
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    if (
      schoolGuidedPathFormData.name &&
      schoolGuidedPathFormData.description &&
      schoolGuidedPathFormData.short_description &&
      Object.keys(schoolGuidedPathFormData.selectedGrade)
    ) {
      let updateUnitObject = {
        name: schoolGuidedPathFormData.name,
        description: schoolGuidedPathFormData.description,
        short_description: schoolGuidedPathFormData.short_description,
        unitId: this.props.schoolGuidedPath.unitEditInfo.id,
        product_id:id
      };
      this.props.schoolGuidedPath.updateUnit(updateUnitObject);
      this.props.ui.hideModal();
      this.props.schoolGuidedPath.setUnitEdit(false);
    } else {
      swal(
        "Oops!",
        "You must enter title, description and long description",
        "error"
      );
    }
  };

  handleContinueBtn = () => {
    const { schoolGuidedPathFormData } = this.state;
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    // console.log(id)
    if (
      schoolGuidedPathFormData.name &&
      schoolGuidedPathFormData.description &&
      schoolGuidedPathFormData.short_description &&
      schoolGuidedPathFormData.selectedSection &&
      Object.keys(schoolGuidedPathFormData.selectedGrade)
    ) {
      let unitObject = {
        name: schoolGuidedPathFormData.name,
        description: schoolGuidedPathFormData.description,
        short_description: schoolGuidedPathFormData.short_description,
        grade: schoolGuidedPathFormData.selectedGrade.id,
        school_class_id: schoolGuidedPathFormData.selectedSection,
        product_id:id
      };
      this.props.schoolGuidedPath.createUnit(unitObject);
      this.props.ui.hideModal();
    } else {
      swal(
        "Oops!",
        "You must enter title, description and long description",
        "error"
      );
    }
  };

  render() {
    const { schoolGuidedPathInfo, schoolGuidedPathFormData } = this.state;
    const { user } = this.props.onboard;
    return (
      <CreateActivityContainer>
        <ActivityMainFormContainer>
          <form>
            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              value={
                schoolGuidedPathFormData &&
                schoolGuidedPathFormData.selectedGrade &&
                Object.keys(schoolGuidedPathFormData.selectedGrade).length >
                  0 &&
                schoolGuidedPathFormData.selectedGrade.name
              }
              disabled={true}
            />

            <Dropdown
              type="select"
              className="form-control"
              autoFocus
              name="sectionDropdown"
              value={schoolGuidedPathFormData.selectedSection}
              onChange={this.handleSection}
              disabled={schoolGuidedPathFormData.id != ""}
            >
              <option value="None">Select Section</option>
              {Object.entries(schoolGuidedPathFormData.section).map(
                ([id, section]) => (
                  <option key={id} value={id}>
                    {section}
                  </option>
                )
              )}
            </Dropdown>
            <RCNameInput
              type="text"
              className="form-control"
              autoFocus
              maxLength="50"
              placeholder="Add Unit Title"
              value={schoolGuidedPathFormData.name}
              disabled={
                this.props.schoolGuidedPath.unitEdit
                  ? !this.props.schoolGuidedPath.unitEdit
                  : true
              }
              onChange={this.handleName}
            />
            <div>
              <PRShortTextArea
                className="form-control"
                placeholder="Add Short description"
                maxLength="50"
                value={schoolGuidedPathFormData.short_description}
                disabled={
                  this.props.schoolGuidedPath.unitEdit
                    ? !this.props.schoolGuidedPath.unitEdit
                    : true
                }
                onChange={this.handleShortDescription}
              />
            </div>
            <div>
              <PRTextArea
                className="form-control"
                placeholder="Add Long description"
                maxLength="200"
                value={schoolGuidedPathFormData.description}
                disabled={
                  this.props.schoolGuidedPath.unitEdit
                    ? !this.props.schoolGuidedPath.unitEdit
                    : true
                }
                onChange={this.handleDescription}
              />
            </div>
            <ActivityContinueButtonContainer>
              {this.props.schoolGuidedPath.unitEditInfo &&
              this.props.schoolGuidedPath.unitEditInfo.id ? (
                this.props.schoolGuidedPath.unitEdit ? (
                  <ThemeButton
                    primary
                    type="button"
                    onClick={this.handleUpdateBtn}
                  >
                    {" "}
                    Update Unit{" "}
                  </ThemeButton>
                ) : (
                  <ThemeButton
                    primary
                    type="button"
                    onClick={this.handleEditBtn}
                  >
                    {" "}
                    Edit Unit{" "}
                  </ThemeButton>
                )
              ) : (
                <CreateRCButton
                  primary
                  className="btn btn-warning"
                  type="button"
                  onClick={() => this.handleContinueBtn()}
                >
                  {" "}
                  Create Unit{" "}
                </CreateRCButton>
              )}
            </ActivityContinueButtonContainer>
          </form>
        </ActivityMainFormContainer>
      </CreateActivityContainer>
    );
  }
}
export default connectStore({
  ui,
  activity,
  schoolGuidedPath,
  onboard,
  user,
})(SchoolGuidedPathCreationForm);
