import React, { Component } from "react";
import { CardContainer, Title } from "./SchoolGuidedPathCard.styled";
import { connectStore } from "redux-box";
import { CardIcon, CardIconContainer } from "static/styled/custom.styled";
import { ToolbarItem } from "@sc/Section.styled";
import { module as ui } from "@st/ui";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import { module as onboard } from "@st/onboard";

class SchoolGuidedPathCard extends Component {
  handleEditClick = (evt) => {
    evt.preventDefault();
    const { schoolGuidedPath, ui, schoolGuidedPathInfo } = this.props;
    schoolGuidedPath.setUnitEditInfo(schoolGuidedPathInfo);
    ui.showModal("SCHOOLGUIDEDPATH");
    // this.props.caseStudy.enableCaseStudyEdit(true);
    // this.props.caseStudy.setCaseStudyId(this.props.caseStudyInfo.id);
  };
  hadleUnlock = (evt) => {
    evt.preventDefault();
    const { schoolGuidedPath, schoolGuidedPathInfo } = this.props;
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    let unit_data ={...schoolGuidedPathInfo,product_id:id};
    unit_data.is_locked = !unit_data.is_locked;
    // console.log(unit_data.is_locked);
    // schoolGuidedPath.setUnitEditInfo(unit_data);
    this.props.schoolGuidedPath.updateUnit(unit_data);
    this.props.ui.hideModal();
    this.props.schoolGuidedPath.setUnitEdit(false);

  };

  render() {
    const { schoolGuidedPathInfo, onboard } = this.props;
    const { user } = onboard;
    const user_group = user.groups[0].name;

    return (
      <CardContainer>

        <CardIconContainer>
          <CardIcon onClick={this.hadleUnlock}>
          {schoolGuidedPathInfo.is_locked ? (
            <i className="fa fa-lock" />
          ) : (
            <i className="fa fa-unlock" />
          )}


          </CardIcon>
          {user_group !== "spectator" ?
            <ToolbarItem primary onClick={this.handleEditClick}>
              <div className="icon-container">
                <i className="fa fa-pencil" />
              </div>
            </ToolbarItem>:null}
          <CardIcon>
            <i className="fa fa-trash" />
          </CardIcon>
        </CardIconContainer>

        <Title>{schoolGuidedPathInfo.name}</Title>
      </CardContainer>
    );
  }
}

export default connectStore({
  ui,
  schoolGuidedPath,
  onboard,
})(SchoolGuidedPathCard);
