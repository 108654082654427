import React, {Component} from 'react';
//import {Container} from './CollectionSection.styled';
import {
    FlexBetween, SubHeading, TagChipsContainer, ThemeButton,TagChips,
    UploadContainer
} from "static/styled/custom.styled";
import {module as story} from "@st/story";
import {connectStore} from "redux-box";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";


class CollectionSection extends Component
{
    handleCollectionTag(collection){
        let {story} = this.props;
        story.requestRemoveStoryCollection(collection.id);
    };

    render(){
        let {story} = this.props;
        let {collection} = this.props;
        const { permission } = this.props.onboard;
        const { user } = this.props.onboard;

        return(
            <UploadContainer>
                <FlexBetween>
                    <SubHeading primary> Related Collections</SubHeading>
                    {user.read_only == false && Permission.getPermission(permission, "change_story")? 
                        <ThemeButton onClick={() => story.setStoryEditorType('collection')}> + New Collection </ThemeButton>
                    :null}
                </FlexBetween>
                {collection && collection.length > 0 && <TagChipsContainer>
                    {collection.map((data,id) => (
                        <TagChips key={id} onClick={() => this.handleCollectionTag(data)}>{data.name}{}
                            <img className="image-fluid" src={require("static/img/tagCloseIcon.png")} alt="Edit icon"/>
                        </TagChips>
                    ))}
                </TagChipsContainer>}
            </UploadContainer>
        )
    }
}

export default connectStore({
    story,onboard
})(CollectionSection);