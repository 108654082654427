import React, { Component } from "react";
import {
  UploadContainer,
  SubHeading,
  TagChipsContainer,
  TagChips,
  ThemeButton,
  FlexBetween
} from "static/styled/custom.styled";
import store from "store";
import action from "@st/activity/actions";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
// export default connectStore({
//   activity
// })
class TagsSection extends Component {
  constructor(props) {
    super();
  }
  handleTagClick = data => {
    let { activity } = this.props;
    const { permission } = this.props.onboard;
    if(Permission.getPermission(permission, "change_activity")){
      activity.deleteTag(data);
    }
  };

  render() {
    const { permission } = this.props.onboard;
    const { user } = this.props.onboard;

    return (
      <UploadContainer>
        {user.read_only == false && Permission.getPermission(permission, "change_activity")? 
          <FlexBetween>
            <SubHeading primary>Related Tags</SubHeading>
            <ThemeButton
              onClick={() => store.dispatch(action.setEditorType("tag"))}
            >
              + New Tag
            </ThemeButton>
          </FlexBetween>
        :null}
        {user.read_only == false && this.props.tags &&
          this.props.tags.length > 0 && (
            <TagChipsContainer>
              {this.props.tags.map((data, id) => (
                <TagChips key={id} onClick={() => this.handleTagClick(data)}>
                  {data}
                  <img
                    className="image-fluid"
                    src={require("static/img/tagCloseIcon.png")}
                    alt="Edit icon"
                  />
                </TagChips>
              ))}
            </TagChipsContainer>
          )}
      </UploadContainer>
    );
  }
}

// export default TagsSection;
export default connectStore({
  activity,onboard
 })(TagsSection)
