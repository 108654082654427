import React, { Component } from "react";
import { connectStore } from "redux-box";

import {
  CardContainer,
  StyledDiv,
  StyledDivImage,
  Title,
} from "@c/activity-view/activities-list/ActivityCard/ActivityCard.styled";
import {
  CardIcon,
  CardIconContainer,
} from "static/styled/custom.styled";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class ProductCard extends Component {
  render() {
    const {product} = this.props;
    const { user } =this.props.onboard;

    return (
      
      <div onClick={this.props.handleProductSelect} >
      <CardContainer>
        <StyledDiv secondary>
          <Title>{product.name}</Title>
          <Title primary>
            {this.props.product &&
            this.props.product.description &&
            this.props.product.description.length < 36
              ? product.description
              : product.description.slice(0, 40) + "..."}
          </Title>
        </StyledDiv>
        <StyledDivImage primary image={product.thumbnail_url}>
          <CardIconContainer>
            {user.read_only == false ?

              <CardIcon onClick={this.props.handleEditClick}>
                <i className="fa fa-pencil" />
              </CardIcon>
            :null
            }
          </CardIconContainer>
        </StyledDivImage>
      </CardContainer>
      </div>
    );
  }
}
export default connectStore({  onboard })(ProductCard);
