import styled from "styled-components";
import { FlexCenter } from "@sc/custom.styled";

export const HeaderMainContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 55px;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  width: 50%;
  // opacity: 0.5;
  font-family: "Rubik";
  font-size: 50px;
  font-weight: bold;
  text-align: left;
  color: #91D8F7;
`;

export const HeaderUserMenuContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
`;

export const HeaderMenuContainer = styled.div`
  margin-top: ${props => (props.primary ? "5px" : "0px")};
  margin-left: ${props => (props.primary ? "0px" : "20px")};
`;

export const LabelUserText = styled.div`
  font-size: ${props => (props.primary ? "14px" : "18px")};
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-right: 50px;
`;

export const NotificationBell = FlexCenter.extend`
  width: 38px;
  height: 38px;
  border: 2px solid #ee8a23;
  border-radius: 100px;
  cursor: pointer;
  background-color: white;
  transition: all 0.2s ease-in-out;
  outine: none;
  margin-right: 8px;
  i {
    color: #ee8a23;
    font-size: 28px;
  }
  &:hover {
    background-color: #ee8a23;
    border: 2px solid white;
    i {
      color: white;
    }
  }
`;

export const LogOutButton = styled.button`
  font-size: 18px;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  outline: none;
  background-color: #fff;
  border: 2px solid #ee8a23;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #ff8a23;
  transition: all 0.4s ease;
  &:hover {
    background: #ff8a23;
    border: 2px solid #fff;
    i {
      color: #fff;
    }
  }
`;
