const getData = (res) => {
  let result = res.map((el) => {
    el.value = el.name;
    el.label = el.name;
    return el;
  });
  return result;
};
const getSectionsData = (res) => {
  if (res && Object.keys(res).length > 0) {
    let result = Object.keys(res).map((key) => {
      let el = {};
      el.value = key;
      el.label = res[key];
      return el;
    });
    return result
  }
};

export default {
  SET_GRADES: (state, { grades }) => {
    state.grades = grades;
    state.gradeList = getData(grades);
  },
  SET_PARENTS: (state, { parents }) => (state.parent_list = parents),
  SET_PARENTS_OFFSET: (state, { offset }) => (state.parents_offset = offset),
  SET_ACTIVE_PARENT: (state, { parent }) => (state.active_parent = parent),
  SET_CHILDREN: (state, { children }) => (state.children_list = children),
  SET_ACTIVE_CHILD: (state, { child }) => (state.active_child = child),
  SET_PARENT_SEARCH: (state, { search }) => (state.search_parent = search),
  SET_CHILD_SEARCH: (state, { search }) => (state.search_child = search),
  UNSET_CHILDREN_LIST: (state) => (state.children_list = []),
  SET_CHILDREN_OFFSET: (state, { offset }) => (state.children_offset = offset),
  SET_CHILD_DATA: (state, { data }) => (state.export_child_data = data),
  SET_PARENT_DATA: (state, { data }) => (state.export_parent_data = data),
  SET_FETCH_PARENT: (state, { value }) => (state.fetch_parent = value),
  SET_FETCH_CHILD: (state, { value }) => (state.fetch_child = value),
  SET_FILTER_PARENT_SEARCH: (state, { data }) => {
    state.filterParentSearch = data;
  },
  SET_FILTER_CHILD_SEARCH: (state, { data }) => {
    state.filterChildSearch = data;
  },
  SET_SCHOOL: (state, { school }) => (state.school = school),
  SET_LINK_SCHOOL: (state, { school }) => (state.link_school = school),
  SET_SECTIONS: (state, { data }) =>{state.sections = data; state.sectionsList = getSectionsData(data)},
  SET_USER_CREATED: (state, { value }) => (state.user_created = value),
  SET_LOADING:(state,{value})=>(state.loading=value),
  SET_EXPORT_DATA:(state,{data})=>(state.exportData=data)
};
