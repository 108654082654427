import React, { Component } from "react";
import NewsFreedsRouter from "@r/newsFreedsRouter";
import {
  MainBodyContainer,
  MainBodyContentContainer,
  PlusButton,
  PlusIcon
} from "@sc/custom.styled";
import NewsFreedsList from "./news-freeds-list/NewsFreedsList";
import NewsFreedsHeader from "./news-freeds-header/NewsFreedsHeader";
import { connectStore } from "redux-box";
import { module as newsFreeds } from "store/newsFreeds";
import { module as ui } from "@st/ui";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import swal from "sweetalert2";

class NewsFreedsView extends Component {
  componentDidMount() {
    this.props.newsFreeds.requestNewsFreeds();
  }

  handleAddClickButton = () => {
    let { newsFreeds, location } = this.props;
    const {permission} = this.props.onboard;
    let activeLevel =
      newsFreeds.active_news_grade_level + "/" + newsFreeds.active_news_level;
    if (location.pathname === "/news-freads/" + activeLevel) {
      if(Permission.getPermission(permission, "add_newsfread")){
        newsFreeds.setActiveNewsFreedDescription("");
        this.props.history.push("/news-freads/" + activeLevel + "/create");
      }else{
        swal({
          text: "You do not have permission.",
          type: "warning"
        });
      }
      
    } else {
      this.props.history.push("/news-freads/" + activeLevel);
    }
  };
 
  render() {
    const { user } = this.props.onboard;
    let { ui } = this.props;
    let isCreatorOpen =
      this.props.location.pathname.includes("/edit") ||
      this.props.location.pathname.includes("/create");
   
    return (
      <MainBodyContainer>
        <NewsFreedsRouter />
        <NewsFreedsHeader />

        <MainBodyContentContainer>
          <NewsFreedsList />
        </MainBodyContentContainer>
        { user.read_only == false ?
        <PlusButton
          onClick={this.handleAddClickButton}
          hideBtn={ui.show_modal === "PLAN"}
        >
          <PlusIcon
            primary
            alt="Plus Icon"
            cross={isCreatorOpen}
            src={require("static/img/plusicon.png")}
          />
        </PlusButton>
        :null} 
      </MainBodyContainer>
    );
  }
}
export default connectStore({
  newsFreeds,
  ui,
  onboard
})(NewsFreedsView)