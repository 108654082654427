import React, { Component } from "react";
import {
  AlignContentHorizontal,
  EmptyContainer,
  AutoContainer,
  LoadingContainer,
  InfiniteScrollParentContainer,
  InfiniteScrollLoader
} from "static/styled/custom.styled";
import {
  NavigationHeaderContainer,
  NavigationContainer
} from "static/styled/NavigationBar.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as readingChallenge } from "@st/readingChallenge";
import { Link } from "react-router-dom";
import swal from "sweetalert2";
import ReadingChallengeCard from "./reading-challenge-card/ReadingChallengeCard";
import InfiniteScroll from "react-infinite-scroller";
import { SearchBarContainer } from "@c/users-view/MainContent.styled";

import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";

class ReadingChallengeList extends Component {
  constructor(props) {
    super();
    this.state = {
      classList: [],
      searchBarEnable: false
    };
  }

  fetchMoreReadingChallenges = () => {
    //let {readingChallenge} = this.props;

    if (
      this.props.readingChallenge.reading_challenges_result !== null &&
      this.props.readingChallenge.reading_challenges_result.has_next
    ) {
      setTimeout(() => {
        if (
          this.props.readingChallenge.reading_challenges_result !== null &&
          this.props.readingChallenge.reading_challenges_result.next_page > 0
        )
          this.props.readingChallenge.requestConcatReadingChallenge(
            this.props.readingChallenge.reading_challenges_result.next_page
          );
      }, 100);
    }
  };
  handleGradeChange = grade => {
    this.props.readingChallenge.setReadingChallengeList([]);
    this.props.readingChallenge.requestReadingChallengeByGradeId(grade.id);
    //console.log(this.props.readingChallenge);
  };
  handlePublishClick = (readingChallenge, e) => {
    e.preventDefault();
    let value = null;
    if (readingChallenge.is_published) value = "unpublish";
    else value = "publish";

    swal({
      text:
        "Are you sure that you want to " + value + " this reading challenge?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.props.readingChallenge.requestUpdateReadingChallenge({
          ...readingChallenge,
          is_published: !readingChallenge.is_published
        });
      }
    });
    e.stopPropagation();
  };

  filterReadingChallenge = () => {
    return this.props.readingChallenge.readingList &&
      this.props.readingChallenge.active_reading_grade
      ? this.props.readingChallenge.readingList.filter(readingChallenge => {
        return (
          readingChallenge.grade ===
          this.props.readingChallenge.active_reading_grade &&
          this.props.readingChallenge.active_reading_grade
        );
      })
      : [];
  };

  handleReadingSearch = evt => {
    let { readingChallenge } = this.props;
    readingChallenge.setSearchReading(evt.target.value);
    setTimeout(() => {
      this.props.readingChallenge.requestReadingChallenge();
    }, 2000);
  };
  handleClearSearch = () => {
    let { readingChallenge } = this.props;
    readingChallenge.setSearchReading("");
    readingChallenge.requestReadingChallenge();
  };

  enableSearchBar = actionType => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false
      });
  };

  render() {
    
    const { user } =this.props.onboard;
    let readingChallenges = this.props.readingChallenge.readingList;
    let classList =
      this.props.activity.grades &&
      this.props.activity.grades.map((grade, i) => (
        <a
          key={i}
          className={
            this.props.readingChallenge.active_reading_grade &&
              grade.id === this.props.readingChallenge.active_reading_grade
              ? "active"
              : ""
          }
          onClick={() => this.handleGradeChange(grade)}
        >
          <span> {grade.name}</span>
        </a>
      ));

    let readingChallengeList = (
      <AlignContentHorizontal marginBottom="70px">
        {readingChallenges && readingChallenges.length > 0 ? (
          readingChallenges.map((readingChallengeInfo, index) => {
            return (
              <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
                  <Link to={"/reading-challenge/edit/" + readingChallengeInfo.id}>
                    <ReadingChallengeCard
                      readingChallengeInfo={readingChallengeInfo}
                      challengeId={activity.id}
                      handlePublishClick={e =>
                        this.handlePublishClick(readingChallengeInfo, e)
                      }
                    />
                  </Link>
              </div>
            );
          })
        ) : (
            <EmptyContainer>
              <h4>NO READING CHALLENGES FOR THIS GRADE</h4>
            </EmptyContainer>
          )}
      </AlignContentHorizontal>
    );

    return (
      <AutoContainer>
        <NavigationHeaderContainer shadow>
          <NavigationContainer>{classList}</NavigationContainer>
          <SearchBarContainer
            width={this.state.searchBarEnable ? "300px" : "200px"}
          >
            <i className="fa fa-search" />
            <input
              type="text"
              onChange={this.handleReadingSearch}
              placeholder="Search"
              value={this.props.readingChallenge.search_reading}
              onFocus={() => this.enableSearchBar("focus")}
              onBlur={() => this.enableSearchBar("blur")}
            />
            {this.props.readingChallenge.search_reading.length > 0 && (
              <i
                className="fa fa-times cross"
                onClick={() => this.handleClearSearch()}
              />
            )}
          </SearchBarContainer>
        </NavigationHeaderContainer>
        {this.props.readingChallenge.fetch_reading_challenge_list ? (
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        ) : (
            <InfiniteScrollParentContainer>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.fetchMoreReadingChallenges}
                style={{ position: "relative" }}
                hasMore={
                  this.props.readingChallenge.reading_challenges_result.has_next
                }
                loader={
                  <InfiniteScrollLoader className="loader" key={0}>
                    <img
                      alt="loader"
                      width="50"
                      height="50"
                      src={require("static/img/Theme-loader.gif")}
                    />
                  </InfiniteScrollLoader>
                }
                useWindow={false}
              >
                {readingChallengeList}{" "}
              </InfiniteScroll>
            </InfiniteScrollParentContainer>
          )}
      </AutoContainer>
    );
  }
}
export default connectStore({
  activity,
  readingChallenge,onboard
})(ReadingChallengeList)