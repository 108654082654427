import React, { Component } from "react";
import { uploadFile } from "@s/api/file";
import {
    ActivityCreatorNavContainer,
    ActivityDropdownLabel,
    ActivityDropdownLabelContainer,
    ActivityGradeDropdown,
    ActivityGradeDropdownContainer,
    ActivityGradeDropdownMenu,
    ActivityGradeSelectOption,
    ActivityDescription,
    ActivityDescriptionInfoContainer,
    ActivityDescriptionTextArea,
    FreadomPointsInputField
} from "@sc/ActivityModal.styled";
import { ActivitySectionAdder } from "@c/activity-view/activity-creator/mcq-editor/McqEditor.styled";
import {
    SelectFileIn,
    ChangeImageButton,
    ActivityInputField,
    ActivityUploadImageButton,ImageContainer
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {EditIcon } from "@c/activity-view/activity-creator/activity-info/ActivityInfo.styled";
import { ThemeButton,ImageLoader,ModalCountItem,ModalCountOverlayContainer} from "@sc/custom.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as readingChallenge} from "@st/readingChallenge";
import {ActivityUploadImageContainer} from "@c/activity-view/activity-pack-creator/activity-pack-info/ActivityPackInfo.styled";
//import {ActivityUploadImageButton} from "activity-view/activity-creation-form/ActivityCreationForm.styled";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

 class ReadingChallengeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {

            readingEdit: true,
            gradeSelected: "",
            enableLoad:false,
            reading_count:0,
            completed_count:0,
            backgroundImageChange:false,
            enableBackgroundImageChange:false,
            imageChange:false,
            displayMenu: false,
            reading_info: {
                id: "",
                grade: "",
                freadom_point: '',
                name: "",
                description: "",
                background_image:'',
                image:''
            }
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.readingChallenge && nextProps.readingChallenge.active_reading_challenge){
            this.normalizeForState(nextProps.readingChallenge.active_reading_challenge)
        }
        else{

            this.setState({
                gradeSelected:'Select Grade'
            })
        }
    }

    normalizeForState = (activeReadingChallenge) => {
        let gradename='';
        let gradeName=this.props.activity.grades.filter((grade)=>
        { return (grade.id === activeReadingChallenge.grade)
        });

        if(gradeName.length>0)
            gradename=gradeName[0].name;
        this.setState({
            gradeSelected:gradename,
            reading_info:{
                ...this.state.reading_info,grade:activeReadingChallenge.grade,
            }
        })

    };

    componentDidMount() {
        let data = this.props.active_reading;
        
        this.setState({
            imageChange: false,
            reading_count:data.reading_count,
            completed_count:data.completed_count,
            reading_info: {
                ...this.state.reading_info,
                id: data.id,
                grade: data.grade,
                freadom_point: data.freadom_point,
                name: data.name,
                description: data.description,
                image:data.image || null,
                background_image:data.background_image,
            }
        });

    }

    handleImageFocus = actionType => {
        if (actionType === "over" && this.state.imageChange === false)
            this.setState({
                imageChange: true
            });
        else if (actionType === "out" && this.state.imageChange === true)
            this.setState({
                imageChange: false
            });
    };

    handleImageChange = e => {
        // e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let fileSize = e.target.files[0].size / 1024 / 1024;
        if (fileSize > 2) {
          swal('Oops!', 'File size greater than 2MB ', 'warning')
        }
        else {
        reader.onloadend = () => {
            this.setState({
                enableLoad:true,
                imageChange:false,
                reading_info: {
                    ...this.state.reading_info,
                    image: reader.result
                }
            });
        };
        reader.readAsDataURL(file);
        uploadFile(file).then(res => {
            this.setState({

                reading_info: {
                    ...this.state.reading_info,
                    image: res.file
                },
                enableLoad:false,
            });
        });
    }
    };


    handleBackgroundImageFocus = actionType => {
        if (actionType === "over" && this.state.backgroundImageChange === false)
            this.setState({
                backgroundImageChange: true
            });
        else if (actionType === "out" && this.state.backgroundImageChange === true)
            this.setState({
                backgroundImageChange: false
            });
    };

    handleBackgroundImageChange = e => {
        // e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let fileSize = e.target.files[0].size / 1024 / 1024;
        if (fileSize > 2) {
          swal('Oops!', 'File size greater than 2MB ', 'warning')
        }
        else {

        reader.onloadend = () => {
            this.setState({
                enableBackgroundImageLoad:true,
                backgroundImageChange:false,
                reading_info: {
                    ...this.state.reading_info,
                    background_image: reader.result
                }
            });
        };
        reader.readAsDataURL(file);
        uploadFile(file).then(res => {
            this.setState({

                reading_info: {
                    ...this.state.reading_info,
                    background_image: res.file
                },
                enableBackgroundImageLoad:false,
            });
        });
    }

    };




    handleReadingNameChange = e => {
        this.setState({
            reading_info: { ...this.state.reading_info, name: e.target.value }
        });
    };
    handleDescriptionValue = e => {
        this.setState({
            reading_info: {
                ...this.state.reading_info,
                description: e.target.value
            }
        });
    };

    handleReadingEditClick = e => {
        this.setState({
            readingEdit: false
        });
    };
    handleDoneBtn = e => {
        this.setState({
            readingEdit: true
        });
        document.getElementById("activityName").removeAttribute("disabled");
        //console.log(this.state.reading_info);
        this.props.readingChallenge.requestUpdateReadingChallenge(this.state.reading_info);
    };
    displayGradeMenu = e => {
        this.setState({
            displayMenu: !this.state.displayMenu
        });
    };
    handleGradeSelected = newgrade => e => {
        this.setState({
            displayMenu: !this.state.displayMenu,
            gradeSelected: newgrade.name,
            reading_info: { ...this.state.reading_info, grade: newgrade.id }
        });
    };

    handleFreadomPoints = evt => {
        if (
            evt.target.value.length === 0 &&
            this.state.reading_info.freadom_point !== ''
        ) {
            this.setState({
                reading_info: {
                    ...this.state.reading_info,
                    freadom_point: ''
                }
            });
        } else
            this.setState({
                reading_info: {
                    ...this.state.reading_info,
                    freadom_point: evt.target.value
                }
            });
    };

    render() {
        const { state } = this;
        const { activity } = this.props;
        const { permission } = this.props.onboard;
        const { user } = this.props.onboard;

        const gradeOptions = activity.grades.map((grade, i) => (
            <ActivityGradeSelectOption
                onClick={this.handleGradeSelected(grade)}
                key={i}
            >
                {grade.name}
            </ActivityGradeSelectOption>
        ));

        return (
            <ActivityDescriptionInfoContainer style={{position:'relative'}}>
                {this.state.reading_info.image==='' ? (
                            <ActivityUploadImageContainer>
                                <ActivityUploadImageButton type="button">
                                    <img src={require("static/img/cloud-upload-icon.png")} alt="Upload" />
                                    <span>Upload Image </span>
                                </ActivityUploadImageButton>

                                <SelectFileIn
                                    type="file"
                                    onChange={e => this.handleImageChange(e)}
                                />
                            </ActivityUploadImageContainer>
                        ) : state.enableLoad?
                            <ActivityUploadImageContainer >
                                <ImageLoader > <img alt="loading" width="30" height="30" src="http://photokonkurs.russia.travel/images/icons/preloader.gif" />
                                </ImageLoader>
                            </ActivityUploadImageContainer>
                            :
                            <ActivityUploadImageContainer
                                onMouseOver={() => this.handleImageFocus("over")}
                                onMouseOut={() => this.handleImageFocus("out")}
                            >
                                <ImageContainer src={this.state.reading_info.image} />
                                {!state.readingEdit?<ChangeImageButton
                                    top={this.state.imageChange ? "82%" : "100%"}
                                    opacity={this.state.imageChange ? 1 : 0}
                                >
                                    Change Image
                                </ChangeImageButton>:null}
                                {!state.readingEdit?<SelectFileIn
                                    type="file"
                                    onChange={e => this.handleImageChange(e)}
                                />:null}
                            </ActivityUploadImageContainer>}
                        {this.state.reading_info.background_image==='' ? (
                            <ActivityUploadImageContainer width="200px" marginLeft="14px">
                                <ActivityUploadImageButton type="button">
                                    <img src={require("static/img/cloud-upload-icon.png")} alt="Upload" />
                                    <span>Upload Fullscreen Image</span>
                                </ActivityUploadImageButton>

                                {!this.state.readingEdit?<SelectFileIn
                                    width="180px"
                                    type="file"
                                    onChange={e => this.handleBackgroundImageChange(e)}
                                />:null}
                            </ActivityUploadImageContainer>
                        ) : this.state.enableBackgroundImageLoad?
                            <ActivityUploadImageContainer width="200px" marginLeft="14px">
                                <ImageLoader > <img alt="loading" width="30" height="30" src="http://photokonkurs.russia.travel/images/icons/preloader.gif" />
                                </ImageLoader>
                            </ActivityUploadImageContainer>
                            :
                            <ActivityUploadImageContainer
                                width="200px" marginLeft="14px"
                                onMouseOver={() => this.handleBackgroundImageFocus("over")}
                                onMouseOut={() => this.handleBackgroundImageFocus("out")}
                            >
                                <ImageContainer src={this.state.reading_info.background_image} />
                                {!this.state.readingEdit?<ChangeImageButton
                                    top={this.state.backgroundImageChange ? "82%" : "100%"}
                                    opacity={this.state.backgroundImageChange ? 1 : 0}
                                >
                                    Change Background
                                </ChangeImageButton>:null}
                                {!this.state.readingEdit?<SelectFileIn
                                    type="file"
                                    onChange={e => this.handleBackgroundImageChange(e)}
                                />:null}
                            </ActivityUploadImageContainer>}
                <ActivityDescription>
                    <ActivitySectionAdder>
                        {this.state.readingEdit ? (
                            <div>
                                Grade : {state.gradeSelected}. Freadom Points:
                                {state.reading_info.freadom_point}
                            </div>
                        ) : (
                            <ActivityCreatorNavContainer>
                                <ActivityGradeDropdownContainer>
                                    <ActivityGradeDropdown onClick={this.displayGradeMenu}>
                                        <ActivityDropdownLabelContainer>
                                            <ActivityDropdownLabel>
                                                {this.state.gradeSelected}
                                            </ActivityDropdownLabel>
                                            <img
                                                src={require("static/img/downarrowicon.png")}
                                                alt="down-arrow"
                                            />
                                        </ActivityDropdownLabelContainer>
                                    </ActivityGradeDropdown>
                                    {this.state.displayMenu ? (
                                        <ActivityGradeDropdownMenu>
                                            {gradeOptions}
                                        </ActivityGradeDropdownMenu>
                                    ) : null}
                                </ActivityGradeDropdownContainer>
                                <FreadomPointsInputField primary width={"10px"}>
                                    <input
                                        type="number"
                                        placeholder="Points"
                                        value={this.state.reading_info.freadom_point}
                                        onChange={this.handleFreadomPoints}
                                    />
                                </FreadomPointsInputField>
                            </ActivityCreatorNavContainer>
                        )}

                        {state.readingEdit ? (
                            <span>
                {user.read_only == false && Permission.getPermission(permission, "change_reading")? 
                <EditIcon
                    src={require("static/img/editicon.png")}
                    alt="edit icon"
                    className="image-fluid"
                    onClick={this.handleReadingEditClick}
                />:null}
              </span>
                        ) : (
                            <ThemeButton primary onClick={this.handleDoneBtn}>

                                Done
                            </ThemeButton>
                        )}
                    </ActivitySectionAdder>
                    <ActivityInputField
                        primary
                        placeholder="New Activity Here"
                        border={
                            state.readingEdit ? "none" : "solid 1px rgba(40,50,112,0.1)"
                        }
                        id="activityName"
                        value={state.reading_info.name}
                        left={!state.readingEdit?"6px":"0px"}
                        width={!state.readingEdit ? "75%" : "100%"}
                        onChange={this.handleReadingNameChange}
                        disabled={this.state.readingEdit}
                    />

                    <ActivityDescriptionTextArea primary={!state.readingEdit}
                        id="activityDescription"
                        disabled={state.readingEdit}
                        onChange={this.handleDescriptionValue}
                        value={state.reading_info.description}
                    />
                </ActivityDescription>
                {this.state.readingEdit && 
         <ModalCountOverlayContainer width="12%" style={{right:'10px'}}>
                  <ModalCountItem  >
                      <span> {state.reading_count}</span>
                      <i className="fa fa-sticky-note-o"/>
                  </ModalCountItem>
                  <ModalCountItem >
                      <span> {state.completed_count} </span>
                      <i className="fa fa-bullseye" />
                  </ModalCountItem>
                  </ModalCountOverlayContainer>}
            </ActivityDescriptionInfoContainer>
        );
    }
}
export default connectStore({
    activity,readingChallenge,onboard
})(ReadingChallengeInfo)