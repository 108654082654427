import styled from "styled-components";
import { ThemeButton } from "static/styled/custom.styled";
import phoneIcon from "static/img/phoneicon.png";
import passwordIcon from "static/img/passwordicon.png";

export const LoginHeading = styled.div`
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #1a1d3e;
`;
export const SuperHeadingLabel = styled.div`
  font-weight: bold;
`;

export const LoginButton = ThemeButton.extend`
  padding: 5px 30px;

  a:hover {
    color: white;
  }
`;

export const LoginBackDrop = styled.div`
  position: fixed;
  background-color: var(--primary-color);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 900;
  transition: 0.3s;
  max-width: 100%;
  background-color: #004EA3;
  // background: url(${require("static/img/freadom_login.png")}) no-repeat;
  background-size: cover;
`;

export const ModalLoginView = styled.div`
  position: relative;
  min-height: 100px;
  min-width: 600px;
  display: flex;
  z-index: 1000;
  flex-flow: row wrap;
  border-radius: 10px;
  background-color: #ffffff;
`;

export const LoginImage = styled.div`
  // width: 50%;
  background-image: url(${require("static/img/cms-logo.png")});
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  width: 220px;
  height: 196px;
  max-width: 100%;
  top: 122px;
  left: 20px;

`;

export const LoginFreadomLogo = styled.div`
  position: absolute;
  background-repeat: no-repeat;
  // background-image: url(${require("static/img/cms-logo.png")});
  width: 272px;
  height: 196px;
  max-width: 100%;
  top: 122px;
  left: 20px;
`;

export const LoginForm = styled.div`
  width: 50%;
  padding: 75px 50px 150px 80px;

  a {
    text-decoration: none;
    color: white;
  }
`;

export const FillFieldLabel = styled.div`
  opacity: 0.6;
  font-family: "Rubik";
  font-size: var(--md-font-size);

  line-height: 2.06;

  text-align: left;
  margin-top: 30px;
  color: #1a1d3e;
`;

export const SuperAdminText = styled.span`
  font-weight: bold;
`;

export const InputFieldLeftIcon = styled.span`
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  position: absolute;
  max-width: 100%;
  background-size: cover;
  top: 10px;
  left: 10px;
  background-image: url(${props => (props.primary ? phoneIcon : passwordIcon)});
`;

export const InputFieldText = styled.input`
  outline: none;
  font-size: var(--md-font-size);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.06;
  letter-spacing: normal;
  text-align: left;
  width: 150px;
  color: rgb(26, 29, 62);
  margin-left: 40px;
  border: none;
  ::placeholder {
    color: rgba(26, 29, 62, 0.6);
    font-size: var(--sm-font-size);
  }
`;
